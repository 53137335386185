import PropTypes from 'prop-types'
import { Linker } from '@/components/content/linker'

Aside.propTypes = {
  tags: PropTypes.array,
  categories: PropTypes.array,
}

export function Aside(props: { tags: any; categories: any; type: string; }) {
  const { tags, categories, type } = props

  const filteredCats = categories?.filter(val =>  val.count > 0).sort((a, b) => (a.title > b.title) ? 1 : -1).filter(Boolean)
  const filteredTags = tags?.filter(val =>  val.count > 0).sort((a, b) => (a.title > b.title) ? 1 : -1).filter(Boolean)

  return (
    <div>
      {/* Categories */}
      {filteredCats.length >= 1 && (<section className='mb-10'>
        <h4 className='block pb-2 mb-4 text-sm font-medium tracking-widest text-gray-500 uppercase border-b-2 title-font border-b-gray-100'>Categories</h4>
        <ul className='block p-0 m-0'>
          {filteredCats.filter(val =>  val.count > 0).map((category: { _id: string; slug: string; title: string; count: number }) =>
            <li className='flex justify-between w-full mb-4 text-base align-baseline' key={category._id}>
              <Linker url={`/${type}/category/${category.slug}`} classes='hover:text-indigo-600'>{category?.title}</Linker>
              <span className='inline-block px-2 py-1 mr-1 text-xs font-semibold text-pink-600 uppercase bg-pink-200 rounded-full last:mr-0 h-fit'>{category?.count}</span></li>
          )}
        </ul>
      </section>)}

      {/* Tags */}
      {filteredTags.length >= 1 && (<section>
        <h4 className='block pb-2 mb-4 text-sm font-medium tracking-widest text-gray-500 uppercase border-b-2 border-b-gray-100'>Tags</h4>
        <ul className='block p-0 m-0'>
          {filteredTags.filter(val =>  val.count > 0).map((tag: { _id: string; slug: string; title: string; count: number }) =>
            <li className='flex justify-between w-full mb-4 text-base align-baseline' key={tag._id}>
              <Linker url={`/${type}/tag/${tag.slug}`} classes='hover:text-indigo-600'>{tag?.title}</Linker>
              <span className='inline-block px-2 py-1 mr-1 text-xs font-semibold text-pink-600 uppercase bg-pink-200 rounded-full last:mr-0'>{tag?.count}</span></li>
          )}
        </ul>
      </section>)}

    </div>
  )
}
