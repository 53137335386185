import Img from 'next/image';
import { useNextSanityImage } from 'next-sanity-image';
import { config } from '@/lib/sanity.config'

export function SuperImage({ image, imageAltText, adjustedHeight, adjustedWidth, fullWidth, classes, priority }) {
  // console.log({ image, imageAltText, adjustedHeight, adjustedWidth, fullWidth, classes, priority })

  const imageWidth = () => {
    if(adjustedWidth){
      return adjustedWidth;
    } else if(fullWidth) {
      return 1920
    } else {
      return false
    }
  }

  const imageHeight = () => {
    if(adjustedHeight){
      return adjustedHeight;
    } else {
      return false
    }
  }

    const imageFit = () => {
    if(adjustedWidth && adjustedHeight) {
      return 'min'
    } else {
      return 'clip'
    }
  }

  const myCustomImageBuilder = (imageUrlBuilder, options) => {
    return imageUrlBuilder
      .width(imageWidth() ? imageWidth() : options.width)
      //  || Math.min(options.croppedImageDimensions.width, 800)
      .height(imageHeight() ? imageHeight() : options.height )
      .fit(imageFit())
		  .quality(options.quality || 80)
  };

  const imageProps = useNextSanityImage(
		config,
		image,
    {
      imageBuilder: myCustomImageBuilder,
			blurUpImageWidth: 124,
			blurUpImageQuality: 30,
			blurUpAmount: 24
    },
	);
  if(fullWidth) {
    delete imageProps["height"]
    delete imageProps["width"]
    return (
      <Img {...imageProps} layout="fill" objectFit="cover" alt={imageAltText || ''} className={classes} priority />
    );
  } else if(adjustedHeight || adjustedWidth) {
    return (<div className='relative' style={`max-width: ${adjustedWidth}px; width: 100%; height: 100%; max-height: ${adjustedHeight}px`}>
      <Img {...imageProps} layout="responsive" sizes={`(max-width: ${adjustedWidth}px) 100vw, ${adjustedWidth}px`} alt={imageAltText || ''} className={classes} /></div>
    );
  } else {
    return (
      <Img {...imageProps} layout="responsive" sizes="(max-width: 800px) 100vw, 800px" alt={imageAltText || ''} className={classes} />
    );
  }
}
