import React, { useRef } from 'react'
import { useIntersectionObserver } from 'usehooks-ts'

import getVideoId from 'get-video-id'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

type YouTubeVideoProps = React.PropsWithChildren<{
  url?: string;
  maxWidth?: string;
  centerAlign: boolean;
}>;

export function YouTubeVideo({ url, maxWidth, centerAlign }) {
  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, {
    root: null,
    // rootMargin: "0px 0px -100%",
    rootMargin: "100%",
    threshold: 0
  })
  const isVisible = !!entry?.isIntersecting
  const { id } = getVideoId(url)
  if (!url || !id) {
    return null
  } else if (url.includes('https') || url.includes('http')) {

    if (centerAlign) {
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: maxWidth ? `${maxWidth}px` : '100%',
            }}
            ref={ref}
          >
            {isVisible && (
              <LiteYouTubeEmbed
                title='YouTube Video'
                id={id}
                adNetwork={false}
                noCookie
              />
            )}
          </div>
        </section></div>
      )
    } else {
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: maxWidth ? `${maxWidth}px` : '100%',
            }}
            ref={ref}
          >
            {isVisible && (
              <LiteYouTubeEmbed
                title='YouTube Video'
                id={id}
                adNetwork={false}
                noCookie
              />
            )}
          </div>
        </section>
      )
    }

  } else {
    return null
  }
}
