import PropTypes from 'prop-types'
// import Link from 'next/link';
// import Image from 'next/image';
import { Tagline } from '@/components/content/tagline'
// import { Linker } from '@/components/content/linker'
import { Card } from '@/components/content/card'
import { Grid } from "@/components/content/grid";
import { Text } from '@/components/layout/content-components/serializers/text'
import imageUrlBuilder from '@sanity/image-url'
import { SanityImageSource } from '@sanity/image-url/lib/types/types';

import { config } from '@/lib/sanity.config'
const builder = imageUrlBuilder(config)

CardWrapper.propTypes = {
  layout: PropTypes.string,
  tagline: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.array,
  cards: PropTypes.array,
  centerAlign: PropTypes.bool
}

export function CardWrapper(props: { layout: string; tagline: string; title: string; text: any; cards: any; centerAlign: boolean }) {
  const imageScaled = (val: SanityImageSource) => {
    return val ? `${builder.image(val).auto('format').width(402).url()}` : null
  }
  const imageBlur = (val: SanityImageSource) => {
    return val ? `${builder.image(val).auto('format').width(10).url()}` : null
  }

  const { layout, title, tagline, text, cards, centerAlign } = props;

  if (centerAlign) {
    if (layout === 'centered') {
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8">
          <div className="relative bg-white">
            <div className="mx-auto text-center">

              {/* Tagline */}
              {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

              {/* Title */}
              {title && <span className='prose text-center md:prose-lg lg:prose-xl'>
                <h2 className="font-extrabold tracking-tight text-gray-900 !mt-1 !mb-3">
                  {title}
                </h2></span>}

              {/* Text */}
              {text && <div className="prose text-left prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline md:prose-lg lg:prose-xl max-w-none">{text && <Text blocks={text} />}</div>}
            </div>

            {/* Cards */}
            {cards &&
              <div className="mt-12">
                <Grid gridSize={cards?.length}>

                  {cards.map((card) => <Card centerAlign={centerAlign} title={card?.title} tagline={card?.tagline} text={card?.text} url={card?.url} image={imageScaled(card.cardImage?.image?.asset)} imageBlur={imageBlur(card?.cardImage?.image?.asset)} imageAltText={card?.cardImage?.alt} key={card._key} callToAction={card?.callToAction} />)}

                </Grid>
              </div>
            }

          </div>
        </section></div>
      )
    } else if (layout === 'side-by-side') {
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          {/* Tagline */}
          {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

          <div className="relative bg-white md:grid md:grid-cols-12 md:gap-8">

            <div className="max-w-2xl md:col-span-5 md:text-left">

              {title && <span className='prose md:prose-lg lg:prose-xl'>
                <h2 className="font-extrabold tracking-tight text-gray-900 !mt-1 !mb-3">
                  {title}
                </h2></span>}

              {/* Text */}
              {text && <div className="prose text-left prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline md:prose-lg lg:prose-xl max-w-none">{text && <Text blocks={text} />}</div>}
            </div>

            {/* Cards */}
            {cards &&
              <div className="relative mt-12 sm:overflow-hidden sm:mx-auto md:mt-0 md:mx-0 md:col-span-7">
                <Grid gridSize={2}>
                  {cards.map(card => <Card centerAlign={centerAlign} title={card?.title} tagline={card?.tagline} text={card?.text} url={card?.url} image={imageScaled(card.cardImage?.image?.asset)} imageBlur={imageBlur(card?.cardImage?.image?.asset)} imageAltText={card?.cardImage?.alt} key={card._key} callToAction={card?.callToAction} />)}
                </Grid>
              </div>
            }
          </div>
        </section></div>
      )
    } else { // Stacked (default) layout
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          <div className="relative bg-white">
            <div className="max-w-md text-left sm:max-w-3xl lg:max-w-7xl">

              {/* Tagline */}
              {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

              {/* Title */}
              {title && <span className='prose md:prose-lg lg:prose-xl'>
                <h2 className="font-extrabold tracking-tight text-gray-900 !mt-1 !mb-3">
                  {title}
                </h2></span>}

              {/* Text */}
              {text && <div className="prose text-left prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline md:prose-lg lg:prose-xl max-w-none">{text && <Text blocks={text} />}</div>}

            </div>

            {/* Cards */}
            {cards &&
              <div className="mt-12">
                <Grid gridSize={cards?.length}>
                  {cards.map(card => <Card centerAlign={centerAlign} title={card?.title} tagline={card?.tagline} text={card?.text} url={card?.url} image={imageScaled(card.cardImage?.image?.asset)} imageBlur={imageBlur(card?.cardImage?.image?.asset)} imageAltText={card?.cardImage?.alt} key={card._key} callToAction={card?.callToAction} />)}
                </Grid>
              </div>
            }
          </div>
        </section></div>
      )
    }


  } else {
    if (layout === 'centered') {
      return (
        <section className="mb-8 inner-wrapper">
          <div className="relative bg-white">
            <div className="max-w-md mx-auto text-center sm:max-w-3xl md:px-6 lg:px-8 lg:max-w-7xl">

              {/* Tagline */}
              {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

              {/* Title */}
              {title && <span className='prose text-center md:prose-lg lg:prose-xl'>
                <h2 className="font-extrabold tracking-tight text-gray-900 !mt-1 !mb-3">
                  {title}
                </h2></span>}

              {/* Text */}
              {text && <div className="prose text-left prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline md:prose-lg lg:prose-xl max-w-none">{text && <Text blocks={text} />}</div>}
            </div>

            {/* Cards */}
            {cards &&
              <div className="mt-12">
                <Grid gridSize={cards?.length}>

                  {cards.map((card) => <Card centerAlign={centerAlign} title={card?.title} tagline={card?.tagline} text={card?.text} url={card?.url} image={imageScaled(card.cardImage?.image?.asset)} imageBlur={imageBlur(card?.cardImage?.image?.asset)} imageAltText={card?.cardImage?.alt} key={card._key} callToAction={card?.callToAction} />)}

                </Grid>
              </div>
            }

          </div>
        </section>
      )
    } else if (layout === 'side-by-side') {
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          {/* Tagline */}
          {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

          <div className="relative bg-white md:grid md:grid-cols-12 md:gap-8">

            <div className="max-w-2xl md:col-span-4 md:text-left">

              {title && <span className='prose md:prose-lg lg:prose-xl'>
                <h2 className="font-extrabold tracking-tight text-gray-900 !mt-1 !mb-3">
                  {title}
                </h2></span>}

              {/* Text */}
              {text && <div className="prose text-left prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline md:prose-lg lg:prose-xl max-w-none">{text && <Text blocks={text} />}</div>}
            </div>

            {/* Cards */}
            {cards &&
              <div className="relative mt-12 sm:overflow-hidden sm:mx-auto md:mt-0 md:mx-0 md:col-span-8">
                <Grid gridSize={cards?.length}>
                  {cards.map(card => <Card centerAlign={centerAlign} title={card?.title} tagline={card?.tagline} text={card?.text} url={card?.url} image={imageScaled(card.cardImage?.image?.asset)} imageBlur={imageBlur(card?.cardImage?.image?.asset)} imageAltText={card?.cardImage?.alt} key={card._key} callToAction={card?.callToAction} />)}
                </Grid>
              </div>
            }
          </div>
        </section>
      )
    } else { // Stacked (default) layout
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <div className="relative bg-white">
            <div className="max-w-md text-left sm:max-w-3xl lg:max-w-7xl">

              {/* Tagline */}
              {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

              {/* Title */}
              {title && <span className='prose md:prose-lg lg:prose-xl'>
                <h2 className="font-extrabold tracking-tight text-gray-900 !mt-1 !mb-3">
                  {title}
                </h2></span>}

              {/* Text */}
              {text && <div className="prose text-left prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline md:prose-lg lg:prose-xl max-w-none">{text && <Text blocks={text} />}</div>}

            </div>

            {/* Cards */}
            {cards &&
              <div className="mt-12">
                <Grid gridSize={cards?.length}>
                  {cards.map(card => <Card centerAlign={centerAlign} title={card?.title} tagline={card?.tagline} text={card?.text} url={card?.url} image={imageScaled(card.cardImage?.image?.asset)} imageBlur={imageBlur(card?.cardImage?.image?.asset)} imageAltText={card?.cardImage?.alt} key={card._key} callToAction={card?.callToAction} />)}
                </Grid>
              </div>
            }
          </div>
        </section>
      )
    }
  }


}
