import PropTypes from 'prop-types'
import { Text } from '@/components/layout/content-components/serializers/text'
import { CallToAction } from '@/components/content/call-to-action'

CTASection.propTypes = {
  layout: PropTypes.string,
  centerAlign: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  text: PropTypes.array,
  callToActionPrimary: PropTypes.object,
  callToActionSecondary: PropTypes.object,
}

export function CTASection(props: { layout: string; centerAlign: boolean; title: string; subTitle: string; text: any; callToActionPrimary: any; callToActionSecondary: any; }) {

  const { layout, centerAlign, title, subTitle, text, callToActionPrimary, callToActionSecondary } = props

  if (centerAlign) {
    // Narrow Layout -----
    if (layout === 'centered') {
      return (
        <div className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <div className="mx-auto prose text-center max-w-7xl md:prose-lg lg:prose-xl">

            {title && <h2 className="tracking-tight text-gray-900 !mb-0">{title}</h2>}
            {subTitle && <h3 className="tracking-tight text-brand-2-700 !mb-0">{subTitle}</h3>}
            {/* Text */}
            {text && (<div className="mt-4 lg:mx-auto prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline prose-p:!mt-0  max-w-none"><Text blocks={text} /></div>
            )}

            {/* CTA Buttons/Links */}
            {(callToActionPrimary || callToActionSecondary) &&
              <div className="flex flex-col items-center justify-center gap-4 mt-8 text-center sm:flex-row md:gap-6 md:flex-none not-prose">
                {/* CTA - Primary */}

                {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon?.name} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

                {/* CTA - Secondary */}
                {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon?.name} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
              </div>}

          </div>
        </div>
      )
    } else if (layout === 'centered-branded') {
      return (
        <div className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <div className="rounded-md bg-slate-100">
            <div className="max-w-3xl px-4 pt-6 pb-8 mx-auto prose text-center lg:pt-10 lg:pb-12 md:prose-lg lg:prose-xl">

              {title && <h2 className="tracking-tight text-gray-900 !mb-0">{title}</h2>}
              {subTitle && <h3 className="tracking-tight text-brand-2-700 !mb-0">{subTitle}</h3>}
              {/* Text */}
              {text && (<div className="mt-4 lg:mx-auto prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline max-w-none prose-p:!mt-0"><Text blocks={text} /></div>
              )}

              {/* CTA Buttons/Links */}
              {(callToActionPrimary || callToActionSecondary) &&
                <div className="flex flex-col items-center justify-center gap-4 mt-8 text-center sm:flex-row md:gap-6 md:flex-none not-prose">
                  {/* CTA - Primary */}

                  {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

                  {/* CTA - Secondary */}
                  {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
                </div>}
            </div>
          </div>
        </div>
      )
    } else if (layout === 'centered-branded-full') {
      return (
        <div className='mb-8 bg-slate-100 md:mb-12 lg:mb-16 '>
          <div className="px-4 md:px-0 inner-wrapper">

            <div className="max-w-3xl pt-6 pb-8 mx-auto prose text-center lg:pt-10 lg:pb-12 md:prose-lg lg:prose-xl">

              {title && <h2 className="tracking-tight text-gray-900 !mb-0">{title}</h2>}
              {subTitle && <h3 className="tracking-tight text-brand-2-700 !mb-0">{subTitle}</h3>}
              {/* Text */}
              {text && (<div className="mt-4 lg:mx-auto prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline prose-p:!mt-0  max-w-none"><Text blocks={text} /></div>
              )}

              {/* CTA Buttons/Links */}
              {(callToActionPrimary || callToActionSecondary) &&
                <div className="flex flex-col items-center justify-center gap-4 mt-8 text-center sm:flex-row md:gap-6 md:flex-none not-prose">
                  {/* CTA - Primary */}

                  {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

                  {/* CTA - Secondary */}
                  {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
                </div>}

            </div>
          </div>
        </div>
      )
    } else if (layout === 'justified') {
      return (
        <div className="w-full px-3 lg:mx-auto lg:max-w-4xl lg:px-0">
          <div className='max-w-4xl mx-auto'>
            <div className="gap-4 pb-8 mx-auto lg:pb-12 lg:flex lg:items-center lg:justify-between md:gap-6">
              <div className="flex flex-col prose md:prose-lg lg:prose-xl">
                {title && <h2 className="tracking-tight text-gray-900 !mb-0">{title}</h2>}
                {subTitle && <h3 className="tracking-tight text-brand-2-700 !mb-0">{subTitle}</h3>}
                {/* Text */}
                {text && (<div className="mt-4 text-lg lg:text-xl prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline prose-p:!mt-0 prose-p:last-of-type:mb-0  max-w-none"><Text blocks={text} /></div>
                )}
              </div>
              {/* CTA Buttons/Links */}
              {(callToActionPrimary || callToActionSecondary) &&
                <div className="flex flex-col gap-4 mt-8 lg:mt-0 md:gap-6 md:flex-none md:text-right">
                  {/* CTA - Primary */}

                  {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

                  {/* CTA - Secondary */}
                  {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
                </div>}
            </div>
          </div>
        </div>
      )
    } else if (layout === 'justified-branded') {
      return (
        <div className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <div className="rounded-md bg-slate-100">
            <div className='max-w-4xl mx-auto'>
              <div className="gap-4 pt-6 pb-8 mx-auto lg:pt-10 lg:pb-12 lg:flex lg:items-center lg:justify-between md:gap-6">
                <div className="flex flex-col prose md:prose-lg lg:prose-xl">
                  {title && <h2 className="tracking-tight text-gray-900 !mb-0">{title}</h2>}
                  {subTitle && <h3 className="tracking-tight text-brand-2-700 !mb-0">{subTitle}</h3>}
                  {/* Text */}
                  {text && (<div className="mt-4 text-lg lg:text-xl prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline prose-p:!mt-0 prose-p:last-of-type:mb-0  max-w-none"><Text blocks={text} /></div>
                  )}
                </div>
                {/* CTA Buttons/Links */}
                {(callToActionPrimary || callToActionSecondary) &&
                  <div className="flex flex-col gap-4 mt-8 lg:mt-0 md:gap-6 md:flex-none md:text-right">
                    {/* CTA - Primary */}

                    {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

                    {/* CTA - Secondary */}
                    {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
                  </div>}
              </div>
            </div>
          </div>
        </div>
      )
    } else if (layout === 'justified-branded-full') {
      return (<div className="mb-8 bg-slate-100 md:mb-12 lg:mb-16">
        <div className="w-full max-w-4xl mx-auto">
          <div className="gap-4 px-4 pt-6 pb-8 lg:px-0 lg:pt-10 lg:pb-12 lg:flex lg:items-center lg:justify-between md:gap-6">
            <div className="flex flex-col prose md:prose-lg lg:prose-xl">
              {title && <h2 className="tracking-tight text-gray-900 !mb-0">{title}</h2>}
              {subTitle && <h3 className="tracking-tight text-brand-2-700 !mb-0">{subTitle}</h3>}
              {/* Text */}
              {text && (<div className="mt-4 text-lg lg:text-xl prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline prose-p:!mt-0 prose-p:last-of-type:mb-0  max-w-none"><Text blocks={text} /></div>
              )}
            </div>
            {/* CTA Buttons/Links */}
            {(callToActionPrimary || callToActionSecondary) &&
              <div className="flex flex-col gap-4 mt-8 lg:mt-0 md:gap-6 md:flex-none md:text-right">
                {/* CTA - Primary */}

                {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

                {/* CTA - Secondary */}
                {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
              </div>}
          </div>
        </div>
      </div>)
    } else if (layout === 'stacked') {
      return (
        //  mb-8 md:mb-12 lg:mb-16
        <div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
          <div className="pb-8 mx-auto rounded-md lg:flex lg:flex-col lg:pb-12">

            <div className="flex flex-col prose md:prose-lg lg:prose-xl">
              {title && <h2 className="tracking-tight text-gray-900 !mb-0">{title}</h2>}
              {subTitle && <h3 className="tracking-tight text-brand-2-700 !mb-0">{subTitle}</h3>}
              {/* Text */}
              {text && (<div className="mt-4 text-lg lg:text-xl prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline prose-p:!mt-0 prose-p:last-of-type:mb-0  max-w-none"><Text blocks={text} /></div>
              )}
            </div>

            {/* CTA Buttons/Links */}
            {(callToActionPrimary || callToActionSecondary) &&
              <div className="flex flex-col gap-4 mt-8 sm:flex-row md:gap-6 md:flex-none">
                {/* CTA - Primary */}

                {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

                {/* CTA - Secondary */}
                {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
              </div>}

          </div>
        </div>
      )
    } else if (layout === 'stacked-banded') {
      return (
        // mb-8 rounded-md md:mb-12 lg:mb-16
        <div className="mb-8 rounded-md md:mb-12 lg:mb-16 inner-wrapper bg-slate-100">
          <div className='max-w-4xl mx-auto'>
            <div className="px-4 pt-6 pb-8 mx-auto lg:pt-10 lg:pb-12 lg:flex lg:flex-col lg:px-0">

              <div className="flex flex-col prose md:prose-lg lg:prose-xl">
                {title && <h2 className="tracking-tight text-gray-900 !mb-0">{title}</h2>}
                {subTitle && <h3 className="tracking-tight text-brand-2-700 !mb-0">{subTitle}</h3>}
                {/* Text */}
                {text && (<div className="mt-4 text-lg lg:text-xl prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline prose-p:!mt-0 prose-p:last-of-type:mb-0  max-w-none"><Text blocks={text} /></div>
                )}
              </div>

              {/* CTA Buttons/Links */}
              {(callToActionPrimary || callToActionSecondary) &&
                <div className="flex flex-col gap-4 mt-8 sm:flex-row md:gap-6 md:flex-none">
                  {/* CTA - Primary */}

                  {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

                  {/* CTA - Secondary */}
                  {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
                </div>}

            </div>
          </div>
        </div>
      )
    } else if (layout === 'stacked-banded-full') {
      return (
        <div className="mb-8 bg-slate-100 md:mb-12 lg:mb-16">
          <div className="px-3 lg:mx-auto lg:max-w-4xl lg:px-0">
            <div className="flex flex-col pt-6 pb-8 mx-auto lg:pt-10 lg:pb-12">

              <div className="flex flex-col prose md:prose-lg lg:prose-xl">
                {title && <h2 className="tracking-tight text-gray-900 !mb-0">{title}</h2>}
                {subTitle && <h3 className="tracking-tight text-brand-2-700 !mb-0">{subTitle}</h3>}
                {/* Text */}
                {text && (<div className="mt-4 text-lg lg:text-xl prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline prose-p:!mt-0 prose-p:last-of-type:mb-0  max-w-none"><Text blocks={text} /></div>
                )}
              </div>

              {/* CTA Buttons/Links */}
              {(callToActionPrimary || callToActionSecondary) &&
                <div className="flex flex-col gap-4 mt-8 sm:flex-row md:gap-6 md:flex-none">
                  {/* CTA - Primary */}

                  {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

                  {/* CTA - Secondary */}
                  {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
                </div>}

            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
    // Wide Layout -----
  } else {
    if (layout === 'centered') {
      return (
        <div className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <div className="mx-auto prose text-center max-w-7xl md:prose-lg lg:prose-xl">

            {title && <h2 className="tracking-tight text-gray-900 !mb-0">{title}</h2>}
            {subTitle && <h3 className="tracking-tight text-brand-2-700 !mb-0">{subTitle}</h3>}

            {/* Text */}
            {text && (<div className="mt-4 lg:mx-auto prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline lg:max-w-prose prose-p:!mt-0"><Text blocks={text} /></div>
            )}

            {/* CTA Buttons/Links */}
            {(callToActionPrimary || callToActionSecondary) &&
              <div className="flex flex-col items-center justify-center gap-4 mt-8 text-center sm:flex-row md:gap-6 md:flex-none not-prose">
                {/* CTA - Primary */}

                {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon?.name} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

                {/* CTA - Secondary */}
                {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon?.name} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
              </div>}

          </div>
        </div>
      )
    } else if (layout === 'centered-branded') {
      return (
        <div className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <div className="rounded-md bg-slate-100">
            <div className="max-w-3xl px-4 pt-6 pb-8 mx-auto prose text-center lg:pt-10 lg:pb-12 md:prose-lg lg:prose-xl">

              {title && <h2 className="tracking-tight text-gray-900 !mb-0">{title}</h2>}
              {subTitle && <h3 className="tracking-tight text-brand-2-700 !mb-0">{subTitle}</h3>}

              {/* Text */}
              {text && (<div className="mt-4 text-lg lg:mx-auto lg:text-xl prose-p:text-gray-600 prose-a:underline hover:prose-a:no-underline lg:max-w-prose prose-p:!mt-0"><Text blocks={text} /></div>
              )}

              {/* CTA Buttons/Links */}
              {(callToActionPrimary || callToActionSecondary) &&
                <div className="flex flex-col items-center justify-center gap-4 mt-8 text-center sm:flex-row md:gap-6 md:flex-none not-prose">
                  {/* CTA - Primary */}

                  {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

                  {/* CTA - Secondary */}
                  {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
                </div>}
            </div>
          </div>
        </div>
      )
    } else if (layout === 'centered-branded-full') {
      return (
        <div className='mb-8 bg-slate-100 md:mb-12 lg:mb-16 '>
          <div className="px-4 md:px-0 inner-wrapper">

            <div className="max-w-3xl pt-6 pb-8 mx-auto prose text-center lg:pt-10 lg:pb-12 md:prose-lg lg:prose-xl">

              {title && <h2 className="tracking-tight text-gray-900 !mb-0">{title}</h2>}
              {subTitle && <h3 className="tracking-tight text-brand-2-700 !mb-0">{subTitle}</h3>}
              {/* Text */}
              {text && (<div className="mt-4 lg:mx-auto prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline lg:max-w-prose prose-p:!mt-0"><Text blocks={text} /></div>
              )}

              {/* CTA Buttons/Links */}
              {(callToActionPrimary || callToActionSecondary) &&
                <div className="flex flex-col items-center justify-center gap-4 mt-8 text-center sm:flex-row md:gap-6 md:flex-none not-prose">
                  {/* CTA - Primary */}

                  {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

                  {/* CTA - Secondary */}
                  {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
                </div>}

            </div>
          </div>
        </div>
      )
    } else if (layout === 'justified') {
      return (
        <div className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <div className="mx-auto rounded-md lg:flex lg:items-center lg:justify-between">

            <div className="flex flex-col prose md:prose-lg lg:prose-xl">
              {title && <h2 className="tracking-tight text-gray-900 !mb-0">{title}</h2>}
              {subTitle && <h3 className="tracking-tight text-brand-2-700 !mb-0">{subTitle}</h3>}
              {/* Text */}
              {text && (<div className="mt-4 lg:mx-auto prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline lg:max-w-prose prose-p:!mt-0 prose-p:last-of-type:mb-0"><Text blocks={text} /></div>
              )}
            </div>

            {/* CTA Buttons/Links */}
            {(callToActionPrimary || callToActionSecondary) &&
              <div className="flex flex-col gap-4 sm:flex-row md:gap-6 md:flex-none">
                {/* CTA - Primary */}

                {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

                {/* CTA - Secondary */}
                {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
              </div>}

          </div>
        </div>
      )
    } else if (layout === 'justified-branded') {
      return (<div className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <div className="rounded-md bg-slate-100">
          {/* gap-4 pt-6 pb-8 mx-auto lg:pt-10 lg:pb-12 lg:flex lg:items-center lg:justify-between md:gap-6 */}
          <div className="px-4 pt-6 pb-8 mx-auto lg:px-10 lg:pt-10 lg:pb-12 lg:flex lg:items-center lg:justify-between">
            <div className="flex flex-col prose md:prose-lg lg:prose-xl">
              {title && <h2 className="tracking-tight text-gray-900 !mb-0">{title}</h2>}
              {subTitle && <h3 className="tracking-tight text-brand-2-700 !mb-0">{subTitle}</h3>}
              {/* Text */}
              {text && (<div className="mt-4 lg:mx-auto prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline lg:max-w-prose prose-p:!mt-0 prose-p:last-of-type:mb-0"><Text blocks={text} /></div>
              )}
            </div>
            {/* CTA Buttons/Links */}
            {(callToActionPrimary || callToActionSecondary) &&
              <div className="flex flex-col gap-4 mt-8 lg:mt-0 md:gap-6 md:flex-none md:text-right">
                {/* CTA - Primary */}

                {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

                {/* CTA - Secondary */}
                {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
              </div>}
          </div>

        </div>

      </div>
      )
    } else if (layout === 'justified-branded-full') {
      return (<div className="mb-8 bg-slate-100 md:mb-12 lg:mb-16 ">
        <div className="inner-wrapper">
          <div className="px-4 pt-6 pb-8 mx-auto lg:px-10 lg:pt-10 lg:pb-12 lg:flex lg:items-center lg:justify-between">
            <div className="flex flex-col prose md:prose-lg lg:prose-xl">
              {title && <h2 className="tracking-tight text-gray-900 !mb-0">{title}</h2>}
              {subTitle && <h3 className="tracking-tight text-brand-2-700 !mb-0">{subTitle}</h3>}
              {/* Text */}
              {text && (<div className="mt-4 lg:mx-auto prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline lg:max-w-prose prose-p:!mt-0 prose-p:last-of-type:mb-0"><Text blocks={text} /></div>
              )}
            </div>
            {/* CTA Buttons/Links */}
            {(callToActionPrimary || callToActionSecondary) &&
              <div className="flex flex-col gap-4 mt-8 sm:flex-row md:gap-6 md:flex-none">
                {/* CTA - Primary */}

                {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

                {/* CTA - Secondary */}
                {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
              </div>}
          </div>
        </div>
      </div>)
    } else if (layout === 'stacked') {
      return (
        <div className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <div className="mx-auto rounded-md lg:flex lg:flex-col ">

            <div className="flex flex-col prose md:prose-lg lg:prose-xl">
              {title && <h2 className="tracking-tight text-gray-900 !mb-0">{title}</h2>}
              {subTitle && <h3 className="tracking-tight text-brand-2-700 !mb-0">{subTitle}</h3>}
              {/* Text */}
              {text && (<div className="mt-4 text-lg lg:text-xl prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline lg:max-w-prose prose-p:!mt-0 prose-p:last-of-type:mb-0"><Text blocks={text} /></div>
              )}
            </div>

            {/* CTA Buttons/Links */}
            {(callToActionPrimary || callToActionSecondary) &&
              <div className="flex flex-col gap-4 mt-8 sm:flex-row md:gap-6 md:flex-none">
                {/* CTA - Primary */}

                {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

                {/* CTA - Secondary */}
                {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
              </div>}

          </div>
        </div>
      )
    } else if (layout === 'stacked-banded') {
      return (
        <div className="mb-8 rounded-md md:mb-12 lg:mb-16 inner-wrapper ">
          <div className='rounded-md bg-slate-100'>
            <div className="px-4 pt-6 pb-8 mx-auto lg:px-10 lg:pt-10 lg:pb-12 lg:flex lg:flex-col">

              <div className="flex flex-col prose md:prose-lg lg:prose-xl">
                {title && <h2 className="tracking-tight text-gray-900 !mb-0">{title}</h2>}
                {subTitle && <h3 className="tracking-tight text-brand-2-700 !mb-0">{subTitle}</h3>}
                {/* Text */}
                {text && (<div className="mt-4 text-lg lg:text-xl prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline lg:max-w-prose prose-p:!mt-0 prose-p:last-of-type:mb-0"><Text blocks={text} /></div>
                )}
              </div>

              {/* CTA Buttons/Links */}
              {(callToActionPrimary || callToActionSecondary) &&
                <div className="flex flex-col gap-4 mt-8 sm:flex-row md:gap-6 md:flex-none">
                  {/* CTA - Primary */}

                  {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

                  {/* CTA - Secondary */}
                  {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
                </div>}

            </div>
          </div>
        </div>
      )
    } else if (layout === 'stacked-banded-full') {
      return (
        <div className="mb-8 bg-slate-100 md:mb-12 lg:mb-16">
          <div className="inner-wrapper">
            <div className="flex flex-col pt-6 pb-8 mx-auto lg:pt-10 lg:pb-12">

              <div className="flex flex-col prose md:prose-lg lg:prose-xl">
                {title && <h2 className="tracking-tight text-gray-900 !mb-0">{title}</h2>}
                {subTitle && <h3 className="tracking-tight text-brand-2-700 !mb-0">{subTitle}</h3>}
                {/* Text */}
                {text && (<div className="mt-4 text-lg lg:text-xl prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline lg:max-w-prose prose-p:!mt-0 prose-p:last-of-type:mb-0"><Text blocks={text} /></div>
                )}
              </div>

              {/* CTA Buttons/Links */}
              {(callToActionPrimary || callToActionSecondary) &&
                <div className="flex flex-col gap-4 mt-8 sm:flex-row md:gap-6 md:flex-none">
                  {/* CTA - Primary */}

                  {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

                  {/* CTA - Secondary */}
                  {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
                </div>}

            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}
