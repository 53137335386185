import { urlFor } from "@/lib/sanity";
import { Price } from 'types'

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

function hasWhiteSpace(s: string | string[]) {
  return s.indexOf(' ') >= 0;
}

function capitalizeFirstLetter(string: string | any[]) {
  return string ? string[0]?.toUpperCase() + string?.slice(1) : null;
}

function transformToUpperCase(str: any, separators: any[]) {
  if (typeof str === 'string' || str instanceof String){
    separators = separators || [' '];
    var regex = new RegExp('(^|[' + separators.join('') + '])(\\w)', 'g');
    return 'Hi' + str.toLowerCase().replace(regex, function (x: string) { return x.toUpperCase(); }).replace(/-/g, "");
  }
  return null;
}

function toggle24hr(time, onoff){
  if(onoff==undefined) onoff = isNaN(time.replace(':',''))//auto-detect format
  var pm = time.toString().toLowerCase().indexOf('pm')>-1 //check if 'pm' exists in the time string
  time = time.toString().toLowerCase().replace(/[ap]m/,'').split(':') //convert time to an array of numbers
  time[0] = Number(time[0])
  if(onoff){//convert to 24 hour:
      if((pm && time[0]!=12)) time[0] += 12
      else if(!pm && time[0]==12) time[0] = '00'  //handle midnight
      if(String(time[0]).length==1) time[0] = '0'+time[0] //add leading zeros if needed
  }else{ //convert to 12 hour:
      pm = time[0]>=12
      if(!time[0]) time[0]=12 //handle midnight
      else if(pm && time[0]!=12) time[0] -= 12
  }
  return onoff ? time.join(':') : time.join(':')+(pm ? 'pm' : 'am')
}

const truncate = (input: string) => input?.length > 300 ? `${input.substring(0, 325)}...` : input;

function isObject(val) {
  if (val === null) { return false;}
  return ( (typeof val === 'function') || (typeof val === 'object') );
};

function LabelFilter (val) {
  // Replaces '_' with a space and capitalizes the first lettter of each word
  return val.replace(/_/g, ' ').split(' ').map((val) => {
    return val[0].toUpperCase() + val.substring(1)
  }).join(' ')
}

function isNumber(n) {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0)
}

function toCurrency(numberString) {
  if(!isNumber){
    return null
  } else {
    return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol' }).format(numberString)
  }
}

function portableTextToPlainText(blocks = []) {
  return blocks
    // loop through each block
    .map(block => {
      // if it's not a text block with children,
      // return nothing
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      // loop through the children spans, and join the
      // text strings
      return block.children.map(child => child.text).join('')
    })
    // join the paragraphs leaving split by two linebreaks
    .join('\n\n')
}

const scaledImageURL = (val: any, height: number, width: number) => {
  return val ? `${urlFor(val).auto('format').width(height || 800).height(width || 600).url()}` : null
}

function TimeConvert(n) {
  const num = n;
  const hours = (num / 60);
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  const dhours = rhours > 1 ? rhours + " hours " : rhours + " hour ";
  function dminutes(min) {
  if(min === 0) {
    return ''
  } else if(min === 1){
    return min + " and minute "
  } else {
    return min + " and minutes "
  } }
    // rminutes > 1 ? rminutes + " minutes " :
  return dhours + dminutes(rminutes);
}

function isEmpty(value) {
  return (value == null || value === '' || value === undefined);
}

const getURL = () => {
  const url =
    process?.env?.NEXT_PUBLIC_DOMAIN && process?.env?.NEXT_PUBLIC_DOMAIN !== ''
      ? process.env.NEXT_PUBLIC_DOMAIN
      : process?.env?.VERCEL_URL && process?.env?.VERCEL_URL !== ''
      ? process.env.VERCEL_URL
      : 'http://localhost:3000';
  return url.includes('http') ? url : `https://${url}`;
};

const postData = async ({
  url,
  data
}: {
  url: string;
  data?: { price: Price };
}) => {
  console.log('posting,', url, data);

  const res: Response = await fetch(url, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'same-origin',
    body: JSON.stringify(data)
  });

  if (!res.ok) {
    console.log('Error in postData', { url, data, res });
    console.log({res})
    console.log({data})
    throw Error(res.statusText);
  }

  return res.json();
};

const postDataNew = async ({
  url,
  data
}: {
  url: string;
  data?: { id: any, price: any,  };
}) => {
  console.log('posting,', url, data);

  const res: Response = await fetch(url, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'same-origin',
    body: JSON.stringify(data)
  });

  if (!res.ok) {
    console.log('Error in postData', { url, data, res });
    console.log({res})
    console.log({data})
    throw Error(res.statusText);
  }

  return res.json();
};

const toDateTime = (secs: number) => {
  var t = new Date('1970-01-01T00:30:00Z'); // Unix epoch start.
  t.setSeconds(secs);
  return t;
};

const isEmptyObject = (object: object) => {
  for (const property in object) {
    return false;
  }
  return true;
}

const secondsToTime = (e: any) => {
  var h = Math.floor(e / 3600).toString().padStart(2, '0'),
    m = Math.floor(e % 3600 / 60).toString().padStart(2, '0'),
    s = Math.floor(e % 60).toString().padStart(2, '0');
  return `${h}:${m}:${s}`;
}

const camalize = (str) => {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

export { classNames, hasWhiteSpace, capitalizeFirstLetter, transformToUpperCase, toggle24hr, truncate, isObject, LabelFilter, isNumber, toCurrency, portableTextToPlainText, scaledImageURL, TimeConvert, isEmpty, getURL, postData, toDateTime, isEmptyObject, secondsToTime, camalize, postDataNew};
