import Link from 'next/link';
import { useRouter } from 'next/router';

type LinkerProps = React.PropsWithChildren<{
  url: string;
  title?: string;
  ariaLabel?: string;
  classes?: string;
}>;
export function Linker({ url, classes, title, ariaLabel, children }: LinkerProps) {
  const router = useRouter();
  // console.log(router.asPath)
  // console.log(router.pathname, url)
  if (!url) {
    return <>{children}</>
  } else if (url.includes('https') || url.includes('http')) {
    return <a href={url} target='_blank' rel='noopener noreferrer' className={classes ? classes : ''} title={title} aria-label={ariaLabel}>{children}</a>
  } else {
    return <Link href={url}><a className={`${classes ? classes : ''} ${router.asPath === url ? "active" : ""}`} title={title} aria-label={ariaLabel}>{children}</a></Link>
  }
}
