import React, { useRef } from 'react'
import { useIntersectionObserver } from 'usehooks-ts'
import getVideoId from 'get-video-id'

type VimeoVideoProps = React.PropsWithChildren<{
  url: string;
  maxWidth: string;
  centerAlign?: boolean;
}>;

export function VimeoVideo({ url, maxWidth, centerAlign }) {
  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, {})
  const isVisible = !!entry?.isIntersecting
  const id = getVideoId(url).id
  const embedURL = `https://player.vimeo.com/video/${id}`
  if (!url || !id || !embedURL) {
    return null
  } else if (url.includes('https') || url.includes('http')) {

    if (centerAlign) {
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0" ref={ref}>
        <section className="mb-8 md:mb-12 lg:mb-16">
          <div className='mx-auto' style={{ maxWidth: maxWidth ? `${maxWidth}px` : '100%' }}>
            <div className='aspect-w-16 aspect-h-9' >
              {isVisible && (
                <iframe src={embedURL} frameBorder='0' title='Video' allowFullScreen />
              )}
            </div>
          </div>
        </section></div>
      )
    } else {
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper" ref={ref}>
          <div className='mx-auto' style={{ maxWidth: maxWidth ? `${maxWidth}px` : '100%' }}>
            <div className='aspect-w-16 aspect-h-9' >
              {isVisible && (
                <iframe src={embedURL} frameBorder='0' title='Video' allowFullScreen />
              )}
            </div>
          </div>
        </section>
      )
    }

  } else {
    return null
  }
}
