import Image from 'next/image';
import { getImageDimensions } from '@sanity/asset-utils'
import { urlFor } from '@/lib/sanity'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const ImageComponent = ({ image, imageBlur, imageAltText, adjustedHeight, adjustedWidth }) => {
  return (<Image
    src={image}
    alt={imageAltText || ''}
    blurDataURL={imageBlur || ''}
    placeholder={imageBlur ? 'blur' : 'empty'}
    layout="intrinsic"
    width={adjustedWidth}
    height={adjustedHeight}
    className="object-cover object-center w-full"
  />
  )
}

export function Slideshow(props) {

  const adjustedWidth = 896;

  const adjustedHeightVal = (val) => {
    const { aspectRatio } = getImageDimensions(val?.asset)
    return Math.round(896 / aspectRatio)
  }

  const imageScaled = (val) => {
    return val ? `${urlFor(val).auto('format').width(adjustedWidth).height(adjustedHeightVal(val)).url()}` : null
  }

  const imageBlur = (val) => {
    return val ? `${urlFor(val).auto('format').width(10).url()}` : null
  }

  return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
    <section className="mb-8 md:mb-12 lg:mb-16">
      <Carousel showStatus dynamicHeight swipeable showThumbs={false}>
        {props.slides.map(slide =>
          <div key={slide._key}>
            {slide.slideImage && slide.slideImage.asset && slide.slideImage.asset._ref && (
              <ImageComponent key={slide._key} image={imageScaled(slide.slideImage)} imageBlur={imageBlur(slide.slideImage)} imageAltText={slide.slideImage.title} adjustedWidth={adjustedWidth} adjustedHeight={adjustedHeightVal(slide.slideImage)} />
            )}
          </div>
        )}
      </Carousel>
    </section></div>)
}
