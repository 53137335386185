import React from 'react';
import PropTypes from 'prop-types'
// import Link from 'next/link';
// import Image from 'next/image';
import { Tagline } from '@/components/content/tagline'
// import { Linker } from '@/components/content/linker'
import { OptionItem } from '@/components/content/optionsTable/optionItem'
import { Grid } from "@/components/content/grid";
import { Text } from '@/components/layout/content-components/serializers/text'

OptionsTable.propTypes = {
  layout: PropTypes.string,
  tagline: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.array,
  icon: PropTypes.any,
  optionLayout: PropTypes.string,
  optionListTitle: PropTypes.string,
  optionItems: PropTypes.array,
  centerAlign: PropTypes?.bool || false
}

export function OptionsTable(props: { layout: string; tagline: string; title: string; text: any; icon: any; optionLayout: string; optionListTitle: string; optionItems: any; centerAlign: boolean }) {

  const { layout, title, tagline, text, icon, optionLayout, optionListTitle, optionItems, centerAlign } = props

  if (centerAlign) {
    if (layout === 'centered') {
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          <div className="relative bg-white">

            {/* Tagline */}
            {tagline && <Tagline text={tagline} size='md' theme='light' classes='text-center' />}

            {/* Title */}
            {title && <span className='prose text-center md:prose-lg lg:prose-xl'>
              <h2 className="font-extrabold tracking-tight text-gray-900 !mt-1 !mb-3">
                {title}
              </h2></span>}

            {/* Text */}
            {text && <div className="prose text-left prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline md:prose-lg lg:prose-xl max-w-none"><Text blocks={text} /></div>}



            {/* Options */}
            {(optionItems && optionItems?.length > 0) ?
              <div className="mt-12 md:mt-4">
                {optionLayout === 'stacked' ? (
                  <div>
                    {optionItems?.map((option: { _key: string; optionLayout: string; pricePerMonth: number; pricePerYear: number; text: any; title: string; callToAction: any; optionItemText: any; }) => (

                      <OptionItem
                        key={option._key}
                        centerAlign={centerAlign}
                        layout={optionLayout ? optionLayout : null}
                        optionListTitle={optionListTitle ? optionListTitle : null}
                        title={option?.title}
                        icon={icon ? icon : null}
                        pricePerMonth={option?.pricePerMonth}
                        pricePerYear={option?.pricePerYear}
                        text={option?.text}
                        callToAction={option?.callToAction}
                        optionItemText={option?.optionItemText}
                      />
                    ))}
                  </div>
                ) : (
                  <Grid gridSize={optionItems?.length >= 4 ? 2 : optionItems?.length}>
                    {optionItems?.map((option: { _key: string; optionLayout: string; pricePerMonth: number; pricePerYear: number; text: any; title: string; callToAction: any; optionItemText: any; }) => (

                      <OptionItem
                        key={option._key}
                        centerAlign={centerAlign}
                        layout={optionLayout}
                        optionListTitle={optionListTitle}
                        title={option?.title}
                        icon={icon}
                        pricePerMonth={option?.pricePerMonth}
                        pricePerYear={option?.pricePerYear}
                        text={option?.text}
                        callToAction={option?.callToAction}
                        optionItemText={option?.optionItemText}
                      />
                    ))}
                  </Grid>
                )}
              </div>
              : null}

          </div>
        </section>
      </div>
      )
    } else if (layout === 'side-by-side') { // Side-by-side
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          {/* Tagline */}
          {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

          <div className="relative bg-white md:grid md:grid-cols-12 md:gap-12">

            <div className="max-w-2xl md:col-span-6 md:text-left ">

              {/* Title */}
              {title && <span className='prose md:prose-lg lg:prose-xl'>
                <h2 className="font-extrabold tracking-tight text-gray-900 !mt-1 !mb-3">
                  {title}
                </h2></span>}

              {/* Text */}
              {text && <div className="prose prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline md:prose-lg lg:prose-xl max-w-none"><Text blocks={text} /></div>}

            </div>

            {/* Options */}
            {(optionItems && optionItems?.length > 0) ?
              <div className="mt-12 md:col-span-6 md:mt-4">
                {optionLayout === 'stacked' ? (
                  <div>
                    {optionItems?.map((option: { _key: string; optionLayout: string; pricePerMonth: number; pricePerYear: number; text: any; title: string; callToAction: any; optionItemText: any; }) => (

                      <OptionItem
                        key={option._key}
                        centerAlign={centerAlign}
                        layout={optionLayout ? optionLayout : null}
                        optionListTitle={optionListTitle ? optionListTitle : null}
                        title={option?.title}
                        icon={icon ? icon : null}
                        pricePerMonth={option?.pricePerMonth}
                        pricePerYear={option?.pricePerYear}
                        text={option?.text}
                        callToAction={option?.callToAction}
                        optionItemText={option?.optionItemText}
                      />
                    ))}
                  </div>
                ) : (
                  <Grid gridSize={optionItems?.length}>
                    {optionItems?.map((option: { _key: string; optionLayout: string; pricePerMonth: number; pricePerYear: number; text: any; title: string; callToAction: any; optionItemText: any; }) => (

                      <OptionItem
                        key={option._key}
                        centerAlign={centerAlign}
                        layout={optionLayout}
                        optionListTitle={optionListTitle}
                        title={option?.title}
                        icon={icon}
                        pricePerMonth={option?.pricePerMonth}
                        pricePerYear={option?.pricePerYear}
                        text={option?.text}
                        callToAction={option?.callToAction}
                        optionItemText={option?.optionItemText}
                      />
                    ))}
                  </Grid>
                )}
              </div>
              : null}
          </div>
        </section></div>
      )
    } else { // Stacked (default) layout
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          <div className="relative bg-white">

            {/* Tagline */}
            {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

            {/* Title */}
            {title && <span className='prose text-center md:prose-lg lg:prose-xl'>
              <h2 className="font-extrabold tracking-tight text-gray-900 !mt-1 !mb-3">
                {title}
              </h2></span>}

            {/* Text */}
            {text && <div className="prose text-left prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline md:prose-lg lg:prose-xl max-w-none"><Text blocks={text} /></div>}

            {/* Options */}
            {(optionItems && optionItems?.length > 0) ?
              <>
                {optionLayout === 'stacked' ? (
                  <div className='pt-6 md:pt-8'>
                    {optionItems?.map((option: { _key: string; optionLayout: string; pricePerMonth: number; pricePerYear: number; text: any; title: string; callToAction: any; optionItemText: any; }) => (
                      <OptionItem
                        key={option._key}
                        centerAlign={centerAlign}
                        layout={optionLayout ? optionLayout : null}
                        optionListTitle={optionListTitle ? optionListTitle : null}
                        title={option?.title}
                        icon={icon ? icon : null}
                        pricePerMonth={option?.pricePerMonth}
                        pricePerYear={option?.pricePerYear}
                        text={option?.text}
                        callToAction={option?.callToAction}
                        optionItemText={option?.optionItemText}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="mt-12 md:mt-4">
                    <Grid gridSize={optionItems?.length >= 4 ? 2 : optionItems?.length}>
                      {optionItems?.map((option: { _key: string; optionLayout: string; pricePerMonth: number; pricePerYear: number; text: any; title: string; callToAction: any; optionItemText: any; }) => (
                        <OptionItem
                          key={option._key}
                          layout={optionLayout ? optionLayout : null}
                          optionListTitle={optionListTitle ? optionListTitle : null}
                          title={option?.title}
                          icon={icon ? icon : null}
                          pricePerMonth={option?.pricePerMonth}
                          pricePerYear={option?.pricePerYear}
                          text={option?.text}
                          callToAction={option?.callToAction}
                          optionItemText={option?.optionItemText}
                          centerAlign={centerAlign}
                        />
                      ))}
                    </Grid>
                  </div>
                )}
              </>
              : null}
          </div>
        </section></div>
      )
    }
  } else {
    if (layout === 'centered') {
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <div className="relative bg-white">
            <div className="max-w-md mx-auto text-center sm:max-w-3xl md:px-6 lg:px-8 lg:max-w-7xl">

              {/* Tagline */}
              {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

              {/* Title */}
              {title && <span className='prose text-center md:prose-lg lg:prose-xl'>
                <h2 className="font-extrabold tracking-tight text-gray-900 !mt-1 !mb-3">
                  {title}
                </h2></span>}
              {/* Text */}
              {text && <div className="prose text-left prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline md:prose-lg lg:prose-xl max-w-none"><Text blocks={text} /></div>}

            </div>

            {/* Options */}
            {(optionItems && optionItems?.length > 0) ?
              <div className="mt-12 md:mt-4">
                {optionLayout === 'stacked' ? (
                  <div>
                    {optionItems?.map((option: { _key: string; optionLayout: string; pricePerMonth: number; pricePerYear: number; text: any; title: string; callToAction: any; optionItemText: any; }) => (

                      <OptionItem
                        key={option._key}
                        centerAlign={centerAlign}
                        layout={optionLayout ? optionLayout : null}
                        optionListTitle={optionListTitle ? optionListTitle : null}
                        title={option?.title}
                        icon={icon ? icon : null}
                        pricePerMonth={option?.pricePerMonth}
                        pricePerYear={option?.pricePerYear}
                        text={option?.text}
                        callToAction={option?.callToAction}
                        optionItemText={option?.optionItemText}
                      />
                    ))}
                  </div>
                ) : (
                  <Grid gridSize={optionItems?.length}>
                    {optionItems?.map((option: { _key: string; optionLayout: string; pricePerMonth: number; pricePerYear: number; text: any; title: string; callToAction: any; optionItemText: any; }) => (

                      <OptionItem
                        key={option._key}
                        centerAlign={centerAlign}
                        layout={optionLayout}
                        optionListTitle={optionListTitle}
                        title={option?.title}
                        icon={icon}
                        pricePerMonth={option?.pricePerMonth}
                        pricePerYear={option?.pricePerYear}
                        text={option?.text}
                        callToAction={option?.callToAction}
                        optionItemText={option?.optionItemText}
                      />
                    ))}
                  </Grid>
                )}
              </div>
              : null}

          </div>
        </section>
      )
    } else if (layout === 'side-by-side') { // Side-by-side
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          {/* Tagline */}
          {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

          <div className="relative bg-white md:grid md:grid-cols-12 md:gap-12">

            <div className="max-w-2xl md:col-span-4 md:text-left ">

              {/* Title */}
              {title && <span className='prose md:prose-lg lg:prose-xl'>
                <h2 className="font-extrabold tracking-tight text-gray-900 !mt-1 !mb-3">
                  {title}
                </h2></span>}


              {/* Text */}
              {text && <div className="prose text-left prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline md:prose-lg lg:prose-xl max-w-none"><Text blocks={text} /></div>}

            </div>

            {/* Options */}
            {(optionItems && optionItems?.length > 0) ?
              <div className="mt-12 md:col-span-8 md:mt-4">
                {optionLayout === 'stacked' ? (
                  <div>
                    {optionItems?.map((option: { _key: string; optionLayout: string; pricePerMonth: number; pricePerYear: number; text: any; title: string; callToAction: any; optionItemText: any; }) => (

                      <OptionItem
                        key={option._key}
                        centerAlign={centerAlign}
                        layout={optionLayout ? optionLayout : null}
                        optionListTitle={optionListTitle ? optionListTitle : null}
                        title={option?.title}
                        icon={icon ? icon : null}
                        pricePerMonth={option?.pricePerMonth}
                        pricePerYear={option?.pricePerYear}
                        text={option?.text}
                        callToAction={option?.callToAction}
                        optionItemText={option?.optionItemText}
                      />
                    ))}
                  </div>
                ) : (
                  <Grid gridSize={optionItems?.length}>
                    {optionItems?.map((option: { _key: string; optionLayout: string; pricePerMonth: number; pricePerYear: number; text: any; title: string; callToAction: any; optionItemText: any; }) => (

                      <OptionItem
                        key={option._key}
                        centerAlign={centerAlign}
                        layout={optionLayout}
                        optionListTitle={optionListTitle}
                        title={option?.title}
                        icon={icon}
                        pricePerMonth={option?.pricePerMonth}
                        pricePerYear={option?.pricePerYear}
                        text={option?.text}
                        callToAction={option?.callToAction}
                        optionItemText={option?.optionItemText}
                      />
                    ))}
                  </Grid>
                )}
              </div>
              : null}
          </div>
        </section>
      )
    } else { // Stacked (default) layout
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <div className="relative bg-white">
            <div className="max-w-md text-left sm:max-w-3xl lg:max-w-7xl">

              {/* Tagline */}
              {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

              {/* Title */}
              {title && <span className='prose md:prose-lg lg:prose-xl'>
                <h2 className="font-extrabold tracking-tight text-gray-900 !mt-1 !mb-3">
                  {title}
                </h2></span>}

              {/* Text */}
              {text && <div className="prose text-left prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline md:prose-lg lg:prose-xl max-w-none"><Text blocks={text} /></div>}

            </div>

            {/* Options */}
            {(optionItems && optionItems?.length > 0) ?
              <>
                {optionLayout === 'stacked' ? (
                  <div className='pt-6 md:pt-8'>
                    {optionItems?.map((option: { _key: string; optionLayout: string; pricePerMonth: number; pricePerYear: number; text: any; title: string; callToAction: any; optionItemText: any; }) => (
                      <OptionItem
                        key={option._key}
                        centerAlign={centerAlign}
                        layout={optionLayout ? optionLayout : null}
                        optionListTitle={optionListTitle ? optionListTitle : null}
                        title={option?.title}
                        icon={icon ? icon : null}
                        pricePerMonth={option?.pricePerMonth}
                        pricePerYear={option?.pricePerYear}
                        text={option?.text}
                        callToAction={option?.callToAction}
                        optionItemText={option?.optionItemText}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="mt-12 md:mt-4">
                    <Grid gridSize={optionItems?.length}>
                      {optionItems?.map((option: { _key: string; optionLayout: string; pricePerMonth: number; pricePerYear: number; text: any; title: string; callToAction: any; optionItemText: any; }) => (
                        <OptionItem
                          key={option._key}
                          layout={optionLayout ? optionLayout : null}
                          optionListTitle={optionListTitle ? optionListTitle : null}
                          title={option?.title}
                          icon={icon ? icon : null}
                          pricePerMonth={option?.pricePerMonth}
                          pricePerYear={option?.pricePerYear}
                          text={option?.text}
                          callToAction={option?.callToAction}
                          optionItemText={option?.optionItemText}
                          centerAlign={centerAlign}
                        />
                      ))}
                    </Grid>
                  </div>
                )}
              </>
              : null}
          </div>
        </section>
      )
    }
  }
}
