import { SuperImage } from '@/components/content/super-image'

export function ImageComponent({ content }) {
  // console.log({content})
  const { image, width, height, alignment, alt } = content;
  const { centerAlign } = content || false;
  const { fullWidth } = content || false;


  const imgAlignment = (val) => {
    if (val === 'left') {
      return 'text-left'
    } else if (val === 'right') {
      return 'text-right'
    } else {
      return 'text-center'
    }
  }

  if (fullWidth) {
    return (
      <figure className={`full-width mb-8 md:mb-12 lg:mb-16 `} style={{ height: height + 'px' }}>
        <SuperImage image={image} imageAltText={alt || 'Image'} adjustedHeight={height} adjustedWidth={width} fullWidth={fullWidth} classes={''} priority={false} />
      </figure>

    )
  } else {
    // Scaled Images
    if (centerAlign) {
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <figure className={`mb-8 md:mb-12 lg:mb-16 ${imgAlignment(content?.alignment)}`} >
          <SuperImage image={image} imageAltText={alt || 'Image'} adjustedHeight={height} adjustedWidth={width} fullWidth={fullWidth} classes={''} priority={false} />
        </figure></div>
      )
    } else {
      return (
        <figure className={`inner-wrapper mb-8 md:mb-12 lg:mb-16 ${imgAlignment(content?.alignment)}`} >
          <SuperImage image={image} imageAltText={alt || 'Image'} adjustedHeight={height} adjustedWidth={width} fullWidth={fullWidth} classes={''} priority={false} />
        </figure>
      )
    }
  }
}
