import React from 'react'
import { Linker } from '@/components/content/linker'

export function BroadbandFacts({ card }) {

  // 100MbpsAnd250Mbps
  if (card === '100MbpsAnd250Mbps') {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-8 md:gap-x-12 gap-y-12">
        <div className='flex flex-col items-center'>
          <span id="broadband-facts-100mbps-anchor"></span>
          <article className="inline-block w-full p-2 text-sm bg-white border-4 border-gray-800">
            <section className="mb-1 border-b-8 border-gray-800">
              <h1 className="mb-1 text-4xl font-extrabold leading-tight tracking-tight border-b-2 border-gray-800">Broadband Facts</h1>
              <h2 className="mb-1 text-lg font-bold tracking-wide">3 Rivers Communications</h2>
              <h3 className="text-base font-extrabold tracking-wide mb-1.5">100 Mbps</h3>
              <p className="font-semibold mb-0.5">Fixed Broadband Consumer Disclosure</p>
            </section>

            <section className="py-2 mb-2 border-b-2 border-gray-800">
              <h4 className="font-bold text-lg leading-6 mb-1.5 pb-1 border-b-4 border-gray-800">Monthly Price <span className="float-right"> $80.00</span></h4>
              <p className="flex justify-between"><span>This monthly price is an introductory rate</span><span>No</span></p>
            </section>

            <section className="border-b-3 border-gray-800 mb-2 py-0.5">
              <h4 className="font-bold text-lg mb-1.5">Additional Charges &amp; Terms</h4>
              <ul className="px-6 mb-5 list-none">
                <li>Provider Monthly Fees</li>
                <li className="flex justify-between"><span>Provider Monthly Fees</span><span className="font-bold">$80.00</span></li>
              </ul>
              <ul className="px-6 mb-5 list-none">
                <li>One-Time Fees at the Time of Purchase</li>
                <li className="flex justify-between"><span>Activation Fee</span><span className="font-bold">$45.00</span></li>
              </ul>
              <ul className="px-6 mb-0 list-none">
                <li className="flex justify-between"><span>Government Taxes</span><span className="font-bold">Included</span></li>
              </ul>
            </section>

            <section className="py-1 mb-2 border-t-4 border-b-4 border-gray-800">
              <h4 className="font-bold text-lg mb-1.5">Discounts &amp; Bundles</h4>
              <span className="block px-4 py-4">
                <p>Visit the link below for available billing discounts and pricing options for broadband service bundled with voice service.</p>
                <p>
                  <a href="https://www.3rivers.net/internet-services" className="text-blue-700 underline" target="_blank" rel="noreferrer">
                    Click Here
                  </a>
                </p>
              </span>
            </section>

            <section className="py-1 mb-2 border-b border-gray-800">
              <h4 className="font-bold text-lg mb-1.5">Speeds Provided with Plan</h4>
              <ul className="px-6 mb-5 list-none">
                <li className="flex justify-between"><span>Typical Download Speed</span><span className="font-bold">90</span></li>
                <li className="flex justify-between"><span>Typical Upload Speed</span><span className="font-bold">90</span></li>
                <li className="flex justify-between"><span>Typical Latency</span><span className="font-bold">40</span></li>
              </ul>
            </section>

            <section className="py-1 mb-2 border-b-4 border-gray-800">
              <h4 className="font-bold text-md mb-1.5">Data Included with Monthly Price <span className="float-right">Unlimited</span></h4>
            </section>

            <section className="py-1 mb-2 border-b-8 border-gray-800">
              <h4 className="mb-1 text-lg font-bold">Network Management</h4>
              <p className="mb-1">
                <Linker url={"/open-internet-policy"} classes="text-blue-700 underline">
                  Read our Policy
                </Linker>
              </p>
              <h4 className="mb-1 text-lg font-bold">Privacy</h4>
              <p className="mb-1">
                <Linker url={"/privacy-statement"} classes="text-blue-700 underline">Read our Policy
                </Linker>
              </p>
            </section>

            <section className="py-1 mb-2 border-gray-800 border-b-6">
              <h4 className="font-bold text-lg mb-1.5">Customer Support</h4>
              <ul className="list-none px-6 mb-5 grid grid-cols-[1fr] gap-2">
                <li className="flex justify-between"><span className="pr-2">Phone:</span><span className="font-bold">406.467.2535/800.796.4567</span></li>
                <li className="flex justify-between"><span className="pr-2">Email:</span><span><a href="mailto:3rt@3rivers.net" className="font-bold text-blue-700 underline" target="_blank" rel="noreferrer">3rt@3rivers.net</a></span></li>
                <li className="flex justify-between"><span className="pr-2">Website:</span><span>
                  <Linker url={"/"} classes="text-blue-700 underline font-bold">
                    https://www.3rivers.net
                  </Linker>
                </span></li>
              </ul>
            </section>

            <section className="py-1 mb-2">
              <p>Learn more about the terms used on this label by visiting the Federal Communications Commission&#39;s Consumer Resource Center.</p>
              <p className="font-bold text-right"><a href="http://fcc.gov/consumer" className="text-blue-700 underline" target="_blank" rel="noreferrer">fcc.gov/consumer</a></p>
              <p>Unique Plan Identifier: F0001646454ILEC0H1FS002023</p>
            </section>
          </article>
        </div>
        <div className='flex flex-col items-center'>
          <span id="broadband-facts-250mbps-anchor"></span>
          <article className="inline-block w-full p-2 text-sm bg-white border-4 border-gray-800">
            <section className="mb-1 border-b-8 border-gray-800">
              <h1 className="mb-1 text-4xl font-extrabold leading-tight tracking-tight border-b-2 border-gray-800">Broadband Facts</h1>
              <h2 className="mb-1 text-lg font-bold tracking-wide">3 Rivers Communications</h2>
              <h3 className="text-base font-extrabold tracking-wide mb-1.5">250 Mbps</h3>
              <p className="font-semibold mb-0.5">Fixed Broadband Consumer Disclosure</p>
            </section>

            <section className="py-2 mb-2 border-b-2 border-gray-800">
              <h4 className="font-bold text-lg leading-6 mb-1.5 pb-1 border-b-4 border-gray-800">Monthly Price <span className="float-right"> $100.00</span></h4>
              <p className="flex justify-between"><span>This monthly price is an introductory rate</span><span>No</span></p>
            </section>

            <section className="border-b-3 border-gray-800 mb-2 py-0.5">
              <h4 className="font-bold text-lg mb-1.5">Additional Charges &amp; Terms</h4>
              <ul className="px-6 mb-5 list-none">
                <li>Provider Monthly Fees</li>
                <li className="flex justify-between"><span>Provider Monthly Fees</span><span className="font-bold">$100.00</span></li>
              </ul>
              <ul className="px-6 mb-5 list-none">
                <li>One-Time Fees at the Time of Purchase</li>
                <li className="flex justify-between"><span>Activation Fee</span><span className="font-bold">$45.00</span></li>
              </ul>
              <ul className="px-6 mb-0 list-none">
                <li className="flex justify-between"><span>Government Taxes</span><span className="font-bold">Included</span></li>
              </ul>
            </section>

            <section className="py-1 mb-2 border-t-4 border-b-4 border-gray-800">
              <h4 className="font-bold text-lg mb-1.5">Discounts &amp; Bundles</h4>
              <span className="block px-4 py-4">
                <p>Visit the link below for available billing discounts and pricing options for broadband service bundled with voice service.</p>
                <p>
                  <a href="https://www.3rivers.net/internet-services" className="text-blue-700 underline" target="_blank" rel="noreferrer">
                    Click Here
                  </a>
                </p>
              </span>
            </section>

            <section className="py-1 mb-2 border-b border-gray-800">
              <h4 className="font-bold text-lg mb-1.5">Speeds Provided with Plan</h4>
              <ul className="px-6 mb-5 list-none">
                <li className="flex justify-between"><span>Typical Download Speed</span><span className="font-bold">225</span></li>
                <li className="flex justify-between"><span>Typical Upload Speed</span><span className="font-bold">225</span></li>
                <li className="flex justify-between"><span>Typical Latency</span><span className="font-bold">40</span></li>
              </ul>
            </section>

            <section className="py-1 mb-2 border-b-4 border-gray-800">
              <h4 className="font-bold text-md mb-1.5">Data Included with Monthly Price <span className="float-right">Unlimited</span></h4>
            </section>

            <section className="py-1 mb-2 border-b-8 border-gray-800">
              <h4 className="mb-1 text-lg font-bold">Network Management</h4>
              <p className="mb-1">
                <Linker url={"/open-internet-policy"} classes="text-blue-700 underline">
                  Read our Policy
                </Linker>
              </p>
              <h4 className="mb-1 text-lg font-bold">Privacy</h4>
              <p className="mb-1">
                <Linker url={"/privacy-statement"} classes="text-blue-700 underline">Read our Policy
                </Linker>
              </p>
            </section>

            <section className="py-1 mb-2 border-gray-800 border-b-6">
              <h4 className="font-bold text-lg mb-1.5">Customer Support</h4>
              <ul className="list-none px-6 mb-5 grid grid-cols-[1fr] gap-2">
                <li className="flex justify-between"><span className="pr-2">Phone:</span><span className="font-bold">406.467.2535/800.796.4567</span></li>
                <li className="flex justify-between"><span className="pr-2">Email:</span><span><a href="mailto:3rt@3rivers.net" className="font-bold text-blue-700 underline" target="_blank" rel="noreferrer">3rt@3rivers.net</a></span></li>
                <li className="flex justify-between"><span className="pr-2">Website:</span><span>
                  <Linker url={"/"} classes="text-blue-700 underline font-bold">
                    https://www.3rivers.net
                  </Linker>
                </span></li>
              </ul>
            </section>

            <section className="py-1 mb-2">
              <p>Learn more about the terms used on this label by visiting the Federal Communications Commission&#39;s Consumer Resource Center.</p>
              <p className="font-bold text-right"><a href="http://fcc.gov/consumer" className="text-blue-700 underline" target="_blank" rel="noreferrer">fcc.gov/consumer</a></p>
              <p>Unique Plan Identifier: F0001646454ILEC0H2FS002023</p>
            </section>
          </article>
        </div>
      </div>
    )
    // componentViewBroadbandFacts100Mbps
  } else if (card === '100Mbps') {
    return (
      <article className="inline-block max-w-sm p-2 text-sm bg-white border-4 border-gray-800">
        <section className="mb-1 border-b-8 border-gray-800">
          <h1 className="mb-1 text-4xl font-extrabold leading-tight tracking-tight border-b-2 border-gray-800">Broadband Facts</h1>
          <h2 className="mb-1 text-lg font-bold tracking-wide">3 Rivers Communications</h2>
          <h3 className="text-base font-extrabold tracking-wide mb-1.5">100 Mbps</h3>
          <p className="font-semibold mb-0.5">Fixed Broadband Consumer Disclosure</p>
        </section>

        <section className="py-2 mb-2 border-b-2 border-gray-800">
          <h4 className="font-bold text-lg leading-6 mb-1.5 pb-1 border-b-4 border-gray-800">Monthly Price <span className="float-right"> $80.00</span></h4>
          <p className="flex justify-between"><span>This monthly price is an introductory rate</span><span>No</span></p>
        </section>

        <section className="border-b-3 border-gray-800 mb-2 py-0.5">
          <h4 className="font-bold text-lg mb-1.5">Additional Charges &amp; Terms</h4>
          <ul className="px-6 mb-5 list-none">
            <li>Provider Monthly Fees</li>
            <li className="flex justify-between"><span>Provider Monthly Fees</span><span className="font-bold">$80.00</span></li>
          </ul>
          <ul className="px-6 mb-5 list-none">
            <li>One-Time Fees at the Time of Purchase</li>
            <li className="flex justify-between"><span>Activation Fee</span><span className="font-bold">$45.00</span></li>
          </ul>
          <ul className="px-6 mb-0 list-none">
            <li className="flex justify-between"><span>Government Taxes</span><span className="font-bold">Included</span></li>
          </ul>
        </section>

        <section className="py-1 mb-2 border-t-4 border-b-4 border-gray-800">
          <h4 className="font-bold text-lg mb-1.5">Discounts &amp; Bundles</h4>
          <span className="block px-4 py-4">
            <p>Visit the link below for available billing discounts and pricing options for broadband service bundled with voice service.</p>
            <p>
              <a href="https://www.3rivers.net/internet-services" className="text-blue-700 underline" target="_blank" rel="noreferrer">
                Click Here
              </a>
            </p>
          </span>
        </section>

        <section className="py-1 mb-2 border-b border-gray-800">
          <h4 className="font-bold text-lg mb-1.5">Speeds Provided with Plan</h4>
          <ul className="px-6 mb-5 list-none">
            <li className="flex justify-between"><span>Typical Download Speed</span><span className="font-bold">90</span></li>
            <li className="flex justify-between"><span>Typical Upload Speed</span><span className="font-bold">90</span></li>
            <li className="flex justify-between"><span>Typical Latency</span><span className="font-bold">40</span></li>
          </ul>
        </section>

        <section className="py-1 mb-2 border-b-4 border-gray-800">
          <h4 className="font-bold text-md mb-1.5">Data Included with Monthly Price <span className="float-right">Unlimited</span></h4>
        </section>

        <section className="py-1 mb-2 border-b-8 border-gray-800">
          <h4 className="mb-1 text-lg font-bold">Network Management</h4>
          <p className="mb-1">
            <Linker url={"/open-internet-policy"} classes="text-blue-700 underline">
              Read our Policy
            </Linker>
          </p>
          <h4 className="mb-1 text-lg font-bold">Privacy</h4>
          <p className="mb-1">
            <Linker url={"/privacy-statement"} classes="text-blue-700 underline">Read our Policy
            </Linker>
          </p>
        </section>

        <section className="py-1 mb-2 border-gray-800 border-b-6">
          <h4 className="font-bold text-lg mb-1.5">Customer Support</h4>
          <ul className="list-none px-6 mb-5 grid grid-cols-[1fr] gap-2">
            <li className="flex justify-between"><span className="pr-2">Phone:</span><span className="font-bold">406.467.2535/800.796.4567</span></li>
            <li className="flex justify-between"><span className="pr-2">Email:</span><span><a href="mailto:3rt@3rivers.net" className="font-bold text-blue-700 underline" target="_blank" rel="noreferrer">3rt@3rivers.net</a></span></li>
            <li className="flex justify-between"><span className="pr-2">Website:</span><span>
              <Linker url={"/"} classes="text-blue-700 underline font-bold">
                https://www.3rivers.net
              </Linker>
            </span></li>
          </ul>
        </section>

        <section className="py-1 mb-2">
          <p>Learn more about the terms used on this label by visiting the Federal Communications Commission&#39;s Consumer Resource Center.</p>
          <p className="font-bold text-right"><a href="http://fcc.gov/consumer" className="text-blue-700 underline" target="_blank" rel="noreferrer">fcc.gov/consumer</a></p>
          <p>Unique Plan Identifier: F0001646454ILEC0H1FS002023</p>
        </section>
      </article>
    )
  }
  // componentViewBroadbandFacts250Mbps
  else if (card === '250Mbps') {
    return (
      <article className="inline-block max-w-sm p-2 text-sm bg-white border-4 border-gray-800">
        <section className="mb-1 border-b-8 border-gray-800">
          <h1 className="mb-1 text-4xl font-extrabold leading-tight tracking-tight border-b-2 border-gray-800">Broadband Facts</h1>
          <h2 className="mb-1 text-lg font-bold tracking-wide">3 Rivers Communications</h2>
          <h3 className="text-base font-extrabold tracking-wide mb-1.5">250 Mbps</h3>
          <p className="font-semibold mb-0.5">Fixed Broadband Consumer Disclosure</p>
        </section>

        <section className="py-2 mb-2 border-b-2 border-gray-800">
          <h4 className="font-bold text-lg leading-6 mb-1.5 pb-1 border-b-4 border-gray-800">Monthly Price <span className="float-right"> $100.00</span></h4>
          <p className="flex justify-between"><span>This monthly price is an introductory rate</span><span>No</span></p>
        </section>

        <section className="border-b-3 border-gray-800 mb-2 py-0.5">
          <h4 className="font-bold text-lg mb-1.5">Additional Charges &amp; Terms</h4>
          <ul className="px-6 mb-5 list-none">
            <li>Provider Monthly Fees</li>
            <li className="flex justify-between"><span>Provider Monthly Fees</span><span className="font-bold">$100.00</span></li>
          </ul>
          <ul className="px-6 mb-5 list-none">
            <li>One-Time Fees at the Time of Purchase</li>
            <li className="flex justify-between"><span>Activation Fee</span><span className="font-bold">$45.00</span></li>
          </ul>
          <ul className="px-6 mb-0 list-none">
            <li className="flex justify-between"><span>Government Taxes</span><span className="font-bold">Included</span></li>
          </ul>
        </section>

        <section className="py-1 mb-2 border-t-4 border-b-4 border-gray-800">
          <h4 className="font-bold text-lg mb-1.5">Discounts &amp; Bundles</h4>
          <span className="block px-4 py-4">
            <p>Visit the link below for available billing discounts and pricing options for broadband service bundled with voice service.</p>
            <p>
              <a href="https://www.3rivers.net/internet-services" className="text-blue-700 underline" target="_blank" rel="noreferrer">
                Click Here
              </a>
            </p>
          </span>
        </section>

        <section className="py-1 mb-2 border-b border-gray-800">
          <h4 className="font-bold text-lg mb-1.5">Speeds Provided with Plan</h4>
          <ul className="px-6 mb-5 list-none">
            <li className="flex justify-between"><span>Typical Download Speed</span><span className="font-bold">225</span></li>
            <li className="flex justify-between"><span>Typical Upload Speed</span><span className="font-bold">225</span></li>
            <li className="flex justify-between"><span>Typical Latency</span><span className="font-bold">40</span></li>
          </ul>
        </section>

        <section className="py-1 mb-2 border-b-4 border-gray-800">
          <h4 className="font-bold text-md mb-1.5">Data Included with Monthly Price <span className="float-right">Unlimited</span></h4>
        </section>

        <section className="py-1 mb-2 border-b-8 border-gray-800">
          <h4 className="mb-1 text-lg font-bold">Network Management</h4>
          <p className="mb-1">
            <Linker url={"/open-internet-policy"} classes="text-blue-700 underline">
              Read our Policy
            </Linker>
          </p>
          <h4 className="mb-1 text-lg font-bold">Privacy</h4>
          <p className="mb-1">
            <Linker url={"/privacy-statement"} classes="text-blue-700 underline">Read our Policy
            </Linker>
          </p>
        </section>

        <section className="py-1 mb-2 border-gray-800 border-b-6">
          <h4 className="font-bold text-lg mb-1.5">Customer Support</h4>
          <ul className="list-none px-6 mb-5 grid grid-cols-[1fr] gap-2">
            <li className="flex justify-between"><span className="pr-2">Phone:</span><span className="font-bold">406.467.2535/800.796.4567</span></li>
            <li className="flex justify-between"><span className="pr-2">Email:</span><span><a href="mailto:3rt@3rivers.net" className="font-bold text-blue-700 underline" target="_blank" rel="noreferrer">3rt@3rivers.net</a></span></li>
            <li className="flex justify-between"><span className="pr-2">Website:</span><span>
              <Linker url={"/"} classes="text-blue-700 underline font-bold">
                https://www.3rivers.net
              </Linker>
            </span></li>
          </ul>
        </section>

        <section className="py-1 mb-2">
          <p>Learn more about the terms used on this label by visiting the Federal Communications Commission&#39;s Consumer Resource Center.</p>
          <p className="font-bold text-right"><a href="http://fcc.gov/consumer" className="text-blue-700 underline" target="_blank" rel="noreferrer">fcc.gov/consumer</a></p>
          <p>Unique Plan Identifier: F0001646454ILEC0H2FS002023</p>
        </section>
      </article>
    )
  }
  // componentViewBroadbandFacts100MbpsFiberConradShelby 👎🏻
  else if (card === '100MbpsFiberConradShelby') {
    return (
      <div>100MbpsFiberConradShelby</div>
    )
  }
  // componentViewBroadbandFacts100MbpsCopperConradShelby
  else if (card === '100MbpsCopperConradShelby') {
    return (
      <article className="inline-block max-w-sm p-2 text-sm bg-white border-4 border-gray-800">
        <section className="mb-1 border-b-8 border-gray-800">
          <h1 className="mb-1 text-4xl font-extrabold leading-tight tracking-tight border-b-2 border-gray-800">Broadband Facts</h1>
          <h2 className="mb-1 text-lg font-bold tracking-wide">3 Rivers Communications</h2>
          <h3 className="text-base font-extrabold tracking-wide mb-1.5">100 Mbps</h3>
          <p className="font-semibold mb-0.5">Fixed Broadband Consumer Disclosure</p>
        </section>

        <section className="py-2 mb-2 border-b-2 border-gray-800">
          <h4 className="font-bold text-lg leading-6 mb-1.5 pb-1 border-b-4 border-gray-800">Monthly Price <span className="float-right"> $75.00</span></h4>
          <p className="flex justify-between"><span>This monthly price is an introductory rate</span><span>No</span></p>
        </section>

        <section className="border-b-3 border-gray-800 mb-2 py-0.5">
          <h4 className="font-bold text-lg mb-1.5">Additional Charges &amp; Terms</h4>
          <ul className="px-6 mb-5 list-none">
            <li>Provider Monthly Fees</li>
            <li className="flex justify-between"><span>Provider Monthly Fees</span><span className="font-bold">$75.00</span></li>
          </ul>
          <ul className="px-6 mb-5 list-none">
            <li>One-Time Fees at the Time of Purchase</li>
            <li className="flex justify-between"><span>Activation Fee</span><span className="font-bold">$45.00</span></li>
          </ul>
          <ul className="px-6 mb-0 list-none">
            <li className="flex justify-between"><span>Government Taxes</span><span className="font-bold">Included</span></li>
          </ul>
        </section>

        <section className="py-1 mb-2 border-t-4 border-b-4 border-gray-800">
          <h4 className="font-bold text-lg mb-1.5">Discounts &amp; Bundles</h4>
          <span className="block px-4 py-4">
            <p>Visit the link below for available billing discounts and pricing options for broadband service bundled with voice service.</p>
            <p>
              <a href="https://www.3rivers.net/internet-services" className="text-blue-700 underline" target="_blank" rel="noreferrer">
                Click Here
              </a>
            </p>
          </span>
        </section>

        <section className="py-1 mb-2 border-b border-gray-800">
          <h4 className="font-bold text-lg mb-1.5">Speeds Provided with Plan</h4>
          <ul className="px-6 mb-5 list-none">
            <li className="flex justify-between"><span>Typical Download Speed</span><span className="font-bold">27</span></li>
            <li className="flex justify-between"><span>Typical Upload Speed</span><span className="font-bold">9</span></li>
            <li className="flex justify-between"><span>Typical Latency</span><span className="font-bold">40</span></li>
          </ul>
        </section>

        <section className="py-1 mb-2 border-b-4 border-gray-800">
          <h4 className="font-bold text-md mb-1.5">Data Included with Monthly Price <span className="float-right">Unlimited</span></h4>
        </section>

        <section className="py-1 mb-2 border-b-8 border-gray-800">
          <h4 className="mb-1 text-lg font-bold">Network Management</h4>
          <p className="mb-1">
            <Linker url={"/open-internet-policy"} classes="text-blue-700 underline">
              Read our Policy
            </Linker>
          </p>
          <h4 className="mb-1 text-lg font-bold">Privacy</h4>
          <p className="mb-1">
            <Linker url={"/privacy-statement"} classes="text-blue-700 underline">Read our Policy
            </Linker>
          </p>
        </section>

        <section className="py-1 mb-2 border-gray-800 border-b-6">
          <h4 className="font-bold text-lg mb-1.5">Customer Support</h4>
          <ul className="list-none px-6 mb-5 grid grid-cols-[1fr] gap-2">
            <li className="flex justify-between"><span className="pr-2">Phone:</span><span className="font-bold">406.467.2535/800.796.4567</span></li>
            <li className="flex justify-between"><span className="pr-2">Email:</span><span><a href="mailto:3rt@3rivers.net" className="font-bold text-blue-700 underline" target="_blank" rel="noreferrer">3rt@3rivers.net</a></span></li>
            <li className="flex justify-between"><span className="pr-2">Website:</span><span>
              <Linker url={"/"} classes="text-blue-700 underline font-bold">
                https://www.3rivers.net
              </Linker>
            </span></li>
          </ul>
        </section>

        <section className="py-1 mb-2">
          <p>Learn more about the terms used on this label by visiting the Federal Communications Commission&#39;s Consumer Resource Center.</p>
          <p className="font-bold text-right"><a href="http://fcc.gov/consumer" className="text-blue-700 underline" target="_blank" rel="noreferrer">fcc.gov/consumer</a></p>
          <p>Unique Plan Identifier: F0001646454CLEC0H1CS002023</p>
        </section>
      </article>
    )
  }
  // componentViewBroadbandFacts100MbpsCopperAllOtherAreas
  else if (card === '100MbpsCopperAllOtherAreas') {
    return (
      <article className="inline-block max-w-sm p-2 text-sm bg-white border-4 border-gray-800">
        <section className="mb-1 border-b-8 border-gray-800">
          <h1 className="mb-1 text-4xl font-extrabold leading-tight tracking-tight border-b-2 border-gray-800">Broadband Facts</h1>
          <h2 className="mb-1 text-lg font-bold tracking-wide">3 Rivers Communications</h2>
          <h3 className="text-base font-extrabold tracking-wide mb-1.5">100 Mbps</h3>
          <p className="font-semibold mb-0.5">Fixed Broadband Consumer Disclosure</p>
        </section>

        <section className="py-2 mb-2 border-b-2 border-gray-800">
          <h4 className="font-bold text-lg leading-6 mb-1.5 pb-1 border-b-4 border-gray-800">Monthly Price <span className="float-right"> $80.00</span></h4>
          <p className="flex justify-between"><span>This monthly price is an introductory rate</span><span>No</span></p>
        </section>

        <section className="border-b-3 border-gray-800 mb-2 py-0.5">
          <h4 className="font-bold text-lg mb-1.5">Additional Charges &amp; Terms</h4>
          <ul className="px-6 mb-5 list-none">
            <li>Provider Monthly Fees</li>
            <li className="flex justify-between"><span>Provider Monthly Fees</span><span className="font-bold">$80.00</span></li>
          </ul>
          <ul className="px-6 mb-5 list-none">
            <li>One-Time Fees at the Time of Purchase</li>
            <li className="flex justify-between"><span>Activation Fee</span><span className="font-bold">$45.00</span></li>
          </ul>
          <ul className="px-6 mb-0 list-none">
            <li className="flex justify-between"><span>Government Taxes</span><span className="font-bold">Included</span></li>
          </ul>
        </section>

        <section className="py-1 mb-2 border-t-4 border-b-4 border-gray-800">
          <h4 className="font-bold text-lg mb-1.5">Discounts &amp; Bundles</h4>
          <span className="block px-4 py-4">
            <p>Visit the link below for available billing discounts and pricing options for broadband service bundled with voice service.</p>
            <p>
              <a href="https://www.3rivers.net/internet-services" className="text-blue-700 underline" target="_blank" rel="noreferrer">
                Click Here
              </a>
            </p>
          </span>
        </section>

        <section className="py-1 mb-2 border-b border-gray-800">
          <h4 className="font-bold text-lg mb-1.5">Speeds Provided with Plan</h4>
          <ul className="px-6 mb-5 list-none">
            <li className="flex justify-between"><span>Typical Download Speed</span><span className="font-bold">27</span></li>
            <li className="flex justify-between"><span>Typical Upload Speed</span><span className="font-bold">9</span></li>
            <li className="flex justify-between"><span>Typical Latency</span><span className="font-bold">40</span></li>
          </ul>
        </section>

        <section className="py-1 mb-2 border-b-4 border-gray-800">
          <h4 className="font-bold text-md mb-1.5">Data Included with Monthly Price <span className="float-right">Unlimited</span></h4>
        </section>

        <section className="py-1 mb-2 border-b-8 border-gray-800">
          <h4 className="mb-1 text-lg font-bold">Network Management</h4>
          <p className="mb-1">
            <Linker url={"/open-internet-policy"} classes="text-blue-700 underline">
              Read our Policy
            </Linker>
          </p>
          <h4 className="mb-1 text-lg font-bold">Privacy</h4>
          <p className="mb-1">
            <Linker url={"/privacy-statement"} classes="text-blue-700 underline">Read our Policy
            </Linker>
          </p>
        </section>

        <section className="py-1 mb-2 border-gray-800 border-b-6">
          <h4 className="font-bold text-lg mb-1.5">Customer Support</h4>
          <ul className="list-none px-6 mb-5 grid grid-cols-[1fr] gap-2">
            <li className="flex justify-between"><span className="pr-2">Phone:</span><span className="font-bold">406.467.2535/800.796.4567</span></li>
            <li className="flex justify-between"><span className="pr-2">Email:</span><span><a href="mailto:3rt@3rivers.net" className="font-bold text-blue-700 underline" target="_blank" rel="noreferrer">3rt@3rivers.net</a></span></li>
            <li className="flex justify-between"><span className="pr-2">Website:</span><span>
              <Linker url={"/"} classes="text-blue-700 underline font-bold">
                https://www.3rivers.net
              </Linker>
            </span></li>
          </ul>
        </section>

        <section className="py-1 mb-2">
          <p>Learn more about the terms used on this label by visiting the Federal Communications Commission&#39;s Consumer Resource Center.</p>
          <p className="font-bold text-right"><a href="http://fcc.gov/consumer" className="text-blue-700 underline" target="_blank" rel="noreferrer">fcc.gov/consumer</a></p>
          <p>Unique Plan Identifier: F0001646454ILEC0H1CS002023</p>
        </section>
      </article>
    )
  }
  // componentViewBroadbandFacts500Mbps
  else if (card === '500Mbps') {
    return (
      <article className="inline-block max-w-sm p-2 text-sm bg-white border-4 border-gray-800">
        <section className="mb-1 border-b-8 border-gray-800">
          <h1 className="mb-1 text-4xl font-extrabold leading-tight tracking-tight border-b-2 border-gray-800">Broadband Facts</h1>
          <h2 className="mb-1 text-lg font-bold tracking-wide">3 Rivers Communications</h2>
          <h3 className="text-base font-extrabold tracking-wide mb-1.5">500 Mbps</h3>
          <p className="font-semibold mb-0.5">Fixed Broadband Consumer Disclosure</p>
        </section>

        <section className="py-2 mb-2 border-b-2 border-gray-800">
          <h4 className="font-bold text-lg leading-6 mb-1.5 pb-1 border-b-4 border-gray-800">Monthly Price <span className="float-right"> $300.00</span></h4>
          <p className="flex justify-between"><span>This monthly price is an introductory rate</span><span>No</span></p>
        </section>

        <section className="border-b-3 border-gray-800 mb-2 py-0.5">
          <h4 className="font-bold text-lg mb-1.5">Additional Charges &amp; Terms</h4>
          <ul className="px-6 mb-5 list-none">
            <li>Provider Monthly Fees</li>
            <li className="flex justify-between"><span>Provider Monthly Fees</span><span className="font-bold">$300.00</span></li>
          </ul>
          <ul className="px-6 mb-5 list-none">
            <li>One-Time Fees at the Time of Purchase</li>
            <li className="flex justify-between"><span>Activation Fee</span><span className="font-bold">$45.00</span></li>
          </ul>
          <ul className="px-6 mb-0 list-none">
            <li className="flex justify-between"><span>Government Taxes</span><span className="font-bold">Included</span></li>
          </ul>
        </section>

        <section className="py-1 mb-2 border-t-4 border-b-4 border-gray-800">
          <h4 className="font-bold text-lg mb-1.5">Discounts &amp; Bundles</h4>
          <span className="block px-4 py-4">
            <p>Visit the link below for available billing discounts and pricing options for broadband service bundled with voice service.</p>
            <p>
              <a href="https://www.3rivers.net/internet-services" className="text-blue-700 underline" target="_blank" rel="noreferrer">
                Click Here
              </a>
            </p>
          </span>
        </section>

        <section className="py-1 mb-2 border-b border-gray-800">
          <h4 className="font-bold text-lg mb-1.5">Speeds Provided with Plan</h4>
          <ul className="px-6 mb-5 list-none">
            <li className="flex justify-between"><span>Typical Download Speed</span><span className="font-bold">450</span></li>
            <li className="flex justify-between"><span>Typical Upload Speed</span><span className="font-bold">450</span></li>
            <li className="flex justify-between"><span>Typical Latency</span><span className="font-bold">40</span></li>
          </ul>
        </section>

        <section className="py-1 mb-2 border-b-4 border-gray-800">
          <h4 className="font-bold text-md mb-1.5">Data Included with Monthly Price <span className="float-right">Unlimited</span></h4>
        </section>

        <section className="py-1 mb-2 border-b-8 border-gray-800">
          <h4 className="mb-1 text-lg font-bold">Network Management</h4>
          <p className="mb-1">
            <Linker url={"/open-internet-policy"} classes="text-blue-700 underline">
              Read our Policy
            </Linker>
          </p>
          <h4 className="mb-1 text-lg font-bold">Privacy</h4>
          <p className="mb-1">
            <Linker url={"/privacy-statement"} classes="text-blue-700 underline">Read our Policy
            </Linker>
          </p>
        </section>

        <section className="py-1 mb-2 border-gray-800 border-b-6">
          <h4 className="font-bold text-lg mb-1.5">Customer Support</h4>
          <ul className="list-none px-6 mb-5 grid grid-cols-[1fr] gap-2">
            <li className="flex justify-between"><span className="pr-2">Phone:</span><span className="font-bold">406.467.2535/800.796.4567</span></li>
            <li className="flex justify-between"><span className="pr-2">Email:</span><span><a href="mailto:3rt@3rivers.net" className="font-bold text-blue-700 underline" target="_blank" rel="noreferrer">3rt@3rivers.net</a></span></li>
            <li className="flex justify-between"><span className="pr-2">Website:</span><span>
              <Linker url={"/"} classes="text-blue-700 underline font-bold">
                https://www.3rivers.net
              </Linker>
            </span></li>
          </ul>
        </section>

        <section className="py-1 mb-2">
          <p>Learn more about the terms used on this label by visiting the Federal Communications Commission&#39;s Consumer Resource Center.</p>
          <p className="font-bold text-right"><a href="http://fcc.gov/consumer" className="text-blue-700 underline" target="_blank" rel="noreferrer">fcc.gov/consumer</a></p>
          <p>Unique Plan Identifier: F0001646454ILEC0H3FS002023</p>
        </section>
      </article>
    )
  }
  // componentViewBroadbandFacts1000Mbps
  else if (card === '1000Mbps') {
    return (
      <article className="inline-block max-w-sm p-2 text-sm bg-white border-4 border-gray-800">
        <section className="mb-1 border-b-8 border-gray-800">
          <h1 className="mb-1 text-4xl font-extrabold leading-tight tracking-tight border-b-2 border-gray-800">Broadband Facts</h1>
          <h2 className="mb-1 text-lg font-bold tracking-wide">3 Rivers Communications</h2>
          <h3 className="text-base font-extrabold tracking-wide mb-1.5">1000 Mbps</h3>
          <p className="font-semibold mb-0.5">Fixed Broadband Consumer Disclosure</p>
        </section>

        <section className="py-2 mb-2 border-b-2 border-gray-800">
          <h4 className="font-bold text-lg leading-6 mb-1.5 pb-1 border-b-4 border-gray-800">Monthly Price <span className="float-right"> $500.00</span></h4>
          <p className="flex justify-between"><span>This monthly price is an introductory rate</span><span>No</span></p>
        </section>

        <section className="border-b-3 border-gray-800 mb-2 py-0.5">
          <h4 className="font-bold text-lg mb-1.5">Additional Charges &amp; Terms</h4>
          <ul className="px-6 mb-5 list-none">
            <li>Provider Monthly Fees</li>
            <li className="flex justify-between"><span>Provider Monthly Fees</span><span className="font-bold">$500.00</span></li>
          </ul>
          <ul className="px-6 mb-5 list-none">
            <li>One-Time Fees at the Time of Purchase</li>
            <li className="flex justify-between"><span>Activation Fee</span><span className="font-bold">$45.00</span></li>
          </ul>
          <ul className="px-6 mb-0 list-none">
            <li className="flex justify-between"><span>Government Taxes</span><span className="font-bold">Included</span></li>
          </ul>
        </section>

        <section className="py-1 mb-2 border-t-4 border-b-4 border-gray-800">
          <h4 className="font-bold text-lg mb-1.5">Discounts &amp; Bundles</h4>
          <span className="block px-4 py-4">
            <p>Visit the link below for available billing discounts and pricing options for broadband service bundled with voice service.</p>
            <p>
              <a href="https://www.3rivers.net/internet-services" className="text-blue-700 underline" target="_blank" rel="noreferrer">
                Click Here
              </a>
            </p>
          </span>
        </section>

        <section className="py-1 mb-2 border-b border-gray-800">
          <h4 className="font-bold text-lg mb-1.5">Speeds Provided with Plan</h4>
          <ul className="px-6 mb-5 list-none">
            <li className="flex justify-between"><span>Typical Download Speed</span><span className="font-bold">900</span></li>
            <li className="flex justify-between"><span>Typical Upload Speed</span><span className="font-bold">900</span></li>
            <li className="flex justify-between"><span>Typical Latency</span><span className="font-bold">40</span></li>
          </ul>
        </section>

        <section className="py-1 mb-2 border-b-4 border-gray-800">
          <h4 className="font-bold text-md mb-1.5">Data Included with Monthly Price <span className="float-right">Unlimited</span></h4>
        </section>

        <section className="py-1 mb-2 border-b-8 border-gray-800">
          <h4 className="mb-1 text-lg font-bold">Network Management</h4>
          <p className="mb-1">
            <Linker url={"/open-internet-policy"} classes="text-blue-700 underline">
              Read our Policy
            </Linker>
          </p>
          <h4 className="mb-1 text-lg font-bold">Privacy</h4>
          <p className="mb-1">
            <Linker url={"/privacy-statement"} classes="text-blue-700 underline">Read our Policy
            </Linker>
          </p>
        </section>

        <section className="py-1 mb-2 border-gray-800 border-b-6">
          <h4 className="font-bold text-lg mb-1.5">Customer Support</h4>
          <ul className="list-none px-6 mb-5 grid grid-cols-[1fr] gap-2">
            <li className="flex justify-between"><span className="pr-2">Phone:</span><span className="font-bold">406.467.2535/800.796.4567</span></li>
            <li className="flex justify-between"><span className="pr-2">Email:</span><span><a href="mailto:3rt@3rivers.net" className="font-bold text-blue-700 underline" target="_blank" rel="noreferrer">3rt@3rivers.net</a></span></li>
            <li className="flex justify-between"><span className="pr-2">Website:</span><span>
              <Linker url={"/"} classes="text-blue-700 underline font-bold">
                https://www.3rivers.net
              </Linker>
            </span></li>
          </ul>
        </section>

        <section className="py-1 mb-2">
          <p>Learn more about the terms used on this label by visiting the Federal Communications Commission&#39;s Consumer Resource Center.</p>
          <p className="font-bold text-right"><a href="http://fcc.gov/consumer" className="text-blue-700 underline" target="_blank" rel="noreferrer">fcc.gov/consumer</a></p>
          <p>Unique Plan Identifier: F0001646454ILEC0H4FS002023</p>
        </section>
      </article>
    )
  }
  // componentViewBroadbandFactsRockcressCommons100Mbps 👎🏻
  else if (card === 'RockcressCommons100Mbps') {
    return (
      <div>RockcressCommons100Mbps</div>
    )
  }
  else {
    return null
  }
}
