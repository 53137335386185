import PropTypes from 'prop-types'
import { Contact } from '@/components/content/view/contact'
import { JobApp } from '@/components/content/view/job-app'
import { ContentView } from '@/components/content/view/content-view/'
import { TeamView } from '@/components/content/view/team/'
import { Subscribe } from '@/components/services/mailchimp/subscribe'
import { UpcomingEvents } from '@/components/content/view/events/'
import { LatestPosts } from '@/components/content/view/posts/'
import { FeaturedPosts } from '@/components/content/view/featured-posts/'
import { BroadbandFacts } from '@/components/content/view/content-view/broadband-facts'

ViewComponent.propTypes = {
  contentView: PropTypes.string.isRequired,
  webform: PropTypes.object,
  view: PropTypes.object,
  filter: PropTypes.string,
  centerAlign: PropTypes.bool
}

export function ViewComponent(props: { contentView: string, webform: any; view: any; filter: any; centerAlign: boolean }) {
  const { contentView, webform, view, filter, centerAlign } = props

  if (centerAlign) {

    if (contentView === 'componentViewContact') {
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          <Contact webform={webform} />
        </section></div>
      )
    } else if (contentView === 'componentViewJobApp') {
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          <JobApp webform={webform} />
        </section></div>
      )
    } else if (contentView === 'componentViewPosts' || contentView === 'componentViewEvents') {
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          <ContentView view={view} />
        </section></div>
      )
    } else if (contentView === 'componentViewTeam') {
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          <TeamView view={view} />
        </section></div>
      )
    } else if (contentView === 'componentViewLatestPosts') {
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          <LatestPosts />
        </section></div>
      )
    } else if (contentView === 'componentViewUpcomingEvents') {
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          <UpcomingEvents />
        </section></div>
      )
    } else if (contentView === 'componentMCSubForm') {
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          {/* <Subscribe /> */}
        </section></div>
      )
    } else if (contentView === 'componentViewFeaturedPosts') {
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          <FeaturedPosts />
        </section></div>
      )

  } else {

    if (contentView === 'componentViewContact') {
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <Contact webform={webform} />
        </section>
      )
    } else if (contentView === 'componentViewJobApp') {
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <JobApp webform={webform} />
        </section>
      )
    } else if (contentView === 'componentViewPosts' || contentView === 'componentViewEvents') {
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <ContentView view={view} />
        </section>
      )
    } else if (contentView === 'componentViewTeam') {
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <TeamView view={view} />
        </section>
      )
    } else if (contentView === 'componentViewLatestPosts') {
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <LatestPosts />
        </section>
      )
    } else if (contentView === 'componentViewUpcomingEvents') {
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <UpcomingEvents />
        </section>
      )
    } else if (contentView === 'componentMCSubForm') {
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          {/* <Subscribe /> */}
        </section>
      )
    } else if (contentView === 'componentViewFeaturedPosts') {
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <FeaturedPosts />
        </section>
      )
    // componentViewBroadbandFacts100MbpsAnd250Mbps
  } else if (contentView === 'componentViewBroadbandFacts100MbpsAnd250Mbps') {
    return (
      <div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          {/* <pre>{JSON.stringify(contentView, null, 2)}</pre> */}
          <BroadbandFacts card="100MbpsAnd250Mbps" />
        </section>
      </div>
    )
    // componentViewBroadbandFacts100Mbps
    } else if (contentView === 'componentViewBroadbandFacts100Mbps') {
      return (
        <div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
          <section className="mb-8 md:mb-12 lg:mb-16">
            {/* <pre>{JSON.stringify(contentView, null, 2)}</pre> */}
            <BroadbandFacts card="100Mbps" />
          </section>
        </div>
      )
    // componentViewBroadbandFacts250Mbps
    } else if (contentView === 'componentViewBroadbandFacts250Mbps') {
      return (
        <div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
          <section className="mb-8 md:mb-12 lg:mb-16">
            {/* <pre>{JSON.stringify(contentView, null, 2)}</pre> */}
            <BroadbandFacts card="250Mbps" />
          </section>
        </div>
      )
    // componentViewBroadbandFacts100MbpsFiberConradShelby
    } else if (contentView === 'componentViewBroadbandFacts100MbpsFiberConradShelby') {
      return (
        <div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
          <section className="mb-8 md:mb-12 lg:mb-16">
          {/* <pre>{JSON.stringify(contentView, null, 2)}</pre> */}
          {/* <BroadbandFacts card="100MbpsFiberConradShelby" /> */}
          </section>
        </div>
      )
      // componentViewBroadbandFacts100MbpsCopperConradShelby
    } else if (contentView === 'componentViewBroadbandFacts100MbpsCopperConradShelby') {
      return (
        <div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
          <section className="mb-8 md:mb-12 lg:mb-16">
          {/* <pre>{JSON.stringify(contentView, null, 2)}</pre> */}
          <BroadbandFacts card="100MbpsCopperConradShelby" />
          </section>
        </div>
      )
    // componentViewBroadbandFacts100MbpsCopperAllOtherAreas
    } else if (contentView === 'componentViewBroadbandFacts100MbpsCopperAllOtherAreas') {
      return (
        <div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
          <section className="mb-8 md:mb-12 lg:mb-16">
          {/* <pre>{JSON.stringify(contentView, null, 2)}</pre> */}
          <BroadbandFacts card="100MbpsCopperAllOtherAreas" />
          </section>
        </div>
      )
    // componentViewBroadbandFacts500Mbps
    } else if (contentView === 'componentViewBroadbandFacts500Mbps') {
      return (
        <div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
          <section className="mb-8 md:mb-12 lg:mb-16">
          {/* <pre>{JSON.stringify(contentView, null, 2)}</pre> */}
          <BroadbandFacts card="500Mbps" />
          </section>
        </div>
      )
    // componentViewBroadbandFacts1000Mbps
    } else if (contentView === 'componentViewBroadbandFacts1000Mbps') {
      return (
        <div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
          <section className="mb-8 md:mb-12 lg:mb-16">
          {/* <pre>{JSON.stringify(contentView, null, 2)}</pre> */}
          <BroadbandFacts card="1000Mbps" />
          </section>
        </div>
      )
    // componentViewBroadbandFactsRockcressCommons100Mbps
    } else if (contentView === 'componentViewBroadbandFactsRockcressCommons100Mbps') {
      return (
        <div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
          <section className="mb-8 md:mb-12 lg:mb-16">
          {/* <pre>{JSON.stringify(contentView, null, 2)}</pre> */}
          {/* <BroadbandFacts card="RockcressCommons100Mbps" /> */}
          </section>
        </div>
      )
    } else {
      return null
    }
  }
  }
}
