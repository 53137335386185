import PropTypes from 'prop-types'
import { Aside } from '@/components/content/view/content-view/aside'
import { BlogView } from '@/components/content/view/content-view/blog-view'
import { EventsView } from '@/components/content/view/content-view/events-view'

ContentView.propTypes = {
  view: PropTypes.object
}

export function ContentView(props: { view: any; }) {
  const { view } = props
  const { tags, categories } = view

  return (
    <div className="grid grid-cols-1 gap-6 mt-8 lg:grid-flow-col-dense lg:grid-cols-4 sm:gap-x-8 md:gap-x-12 gap-y-12">
      {(categories && tags) &&
        <div className="top-0 lg:max-h-screen lg:sticky lg:col-start-4 lg:col-span-1">
          <Aside tags={tags} categories={categories} type={(view?.events && 'events') || (view?.posts && (process.env.NEXT_PUBLIC_BLOG_PATH ? process.env.NEXT_PUBLIC_BLOG_PATH : 'blog')).replace('/', '')} />
        </div>
      }
      <div className="space-y-6 lg:col-start-1 lg:col-span-3">
        {view?.posts && <BlogView data={view?.posts} />}
        {view?.events && <EventsView data={view?.events} />}
      </div>
    </div>
  )

}